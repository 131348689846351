import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Badge, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { StateContext } from '../../components/context/ManageStates';
import { useScrollTop } from '../../hooks';
import { Filters } from '../filters';
import { useMediaQuery } from "react-responsive";
import { RiArrowUpDownFill, RiFilter3Line } from 'react-icons/ri'
import { IoClose } from 'react-icons/io5';
import Sheet from 'react-modal-sheet';
import { useSearch, useFilters } from '../../hooks';
import { useParams, useSearchParams } from 'react-router-dom';

import { useSelector,useDispatch } from 'react-redux';

const MobileFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();

const dispatch = useDispatch()

    const { slug } = useParams();

    const [currentactivelen, setactivelen] = useState([]);


    const productcatData = useSelector(state => state.catlogdetail.productcatData);
    const filterChecked = useSelector(state => state.catlogdetail.filterChecked);
    const filtersbottomsheet = useSelector(state => state.catlogdetail.filtersbottomsheet);
    const selectedItem = useSelector(state => state.catlogdetail.selectedItem);
    const checkItems = useSelector(state => state.catlogdetail.checkItems);



    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    useEffect(() => {
        var queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }

        var finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }))

        if (finalInput.length > 0) {
            var finaloutput = finalInput.flatMap(o => o.value);
            setactivelen(finalInput)
        } else {
            var finaloutput = [];
        }
    }, [slug])

    const handelCheckBox = (e, data) => {
        if (e.target.checked) {
            dispatch({ type: 'setCheckItems', payload: [...checkItems, e.target.value] })
            if (searchParams.has(data.title)) {
                const ParamsValue = searchParams.get(data.title);

                searchParams.delete(data.title);

                searchParams.set(
                    data.title,
                    ParamsValue + "," + e.target.value
                );
            } else {
                searchParams.set(data.title, e.target.value);
            }
        } else {
            const ParamsValue = searchParams.get(data.title);
            var paramsArray = ParamsValue.split(",");
            var filteredArray = paramsArray.filter(
                (ele) => ele !== e.target.value
            );
            searchParams.set(data.title, filteredArray.toString());
            if (filteredArray.length <= 0) {
                searchParams.delete(data.title);
            }
            dispatch({ type: 'setCheckItems', payload: checkItems.filter((each) => each !== e.target.value) })
        }

        var queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }

        var finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }))

        setactivelen(finalInput)

        if (finalInput.length > 0) {
            var finaloutput = finalInput.flatMap(o => o.value);
            var input_data = {
                search: slug,
                "page": 1,
                "limit": 30,
                "filter_update": finaloutput.length > 0 ? 1 : 1,
                "filter_attributes": finaloutput,
                "filter_categories":
                    selectedItem != null ? [selectedItem.split("@")[1]] : []

            }
            // getFilterSearchResult(input_data)
        }

    };

    const clearFilterArray = () => {
        currentactivelen.map((ele) => {
            searchParams.delete(ele.title);
            setSearchParams(searchParams);
            dispatch({ type: 'setCheckItems', payload: [] })
        });
        setactivelen([])
        dispatch({ type: 'setfiltersbottomsheet', payload: false })
    };

    const handleApply = () => {
        setSearchParams(searchParams);
        dispatch({ type: 'setfiltersbottomsheet', payload: false })
    }
    return (
        <Container fluid>
            {
                (productcatData.hasOwnProperty("filters")) ?
                    <>
                        {
                            isDesktoporMobile &&
                            <Sheet isOpen={filtersbottomsheet} onClose={() => dispatch({ type: 'setfiltersbottomsheet', payload: false })}
                            >
                                <Sheet.Container className='custom-filter-modalsheet'>
                                    <Sheet.Content>
                                        <div className='custom-filter-sheet-haeder'>
                                            <div className='filter_title'>Filters</div>
                                            <div className='icon' onClick={() => dispatch({ type: 'setfiltersbottomsheet', payload: false })
                                            }><IoClose size="20" /></div>
                                        </div>


                                        <div className='filter-body'>
                                            <div className='filter-body-leftside'>
                                                {
                                                    productcatData.filters.checkbox.map((ele, index) => {
                                                        return (
                                                            <div
                                                                className={`${filterChecked == index ? 'active-category ' : ''}fOzBOt`}
                                                                onClick={() => dispatch({ type: 'setfilterChecked', payload: index  }) }>
                                                                {ele.title} {<Badge bg="" className='custom-badge-filters'>{currentactivelen.filter(act => act.title == ele.title).length > 0 && currentactivelen.filter(act => act.title == ele.title)[0].value.length}</Badge>}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='filter-body-rightside'>
                                                <div className='filterItems'>
                                                    <ul>
                                                        {
                                                            productcatData.filters.checkbox[filterChecked].list.map(item => {
                                                                return (
                                                                    <li>
                                                                        <div className='list'>
                                                                            <input type='checkbox'
                                                                                value={item.attr_value_id}
                                                                                checked={checkItems.includes(item.attr_value_id)}
                                                                                onChange={(e) => handelCheckBox(e, productcatData.filters.checkbox[filterChecked], item)}
                                                                                id={`inline-checkbox-${item.attr_value_id}`}></input>
                                                                            <label for={`inline-checkbox-${item.id}`}>{item.attr_value_name}</label>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                        <Row>
                                            <div className='buttons-product-detaildiv mobile-filter-apply-btn'>
                                                <div className='product-detail-buttongroup'>
                                                    <div className={`product-detail-button primaryColor  product-detail-buttonactive`} onClick={clearFilterArray}>
                                                        Clear All
                                                    </div>
                                                    <div className={`product-detail-button primaryColor`} onClick={handleApply}>
                                                        Apply
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </Sheet.Content>
                                </Sheet.Container>
                                <Sheet.Backdrop />
                            </Sheet>
                        }
                    </>
                    :
                    <div className='custom-height'></div>
            }

        </Container>

    );
};

export default MobileFilters;