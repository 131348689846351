import React, { useContext, useEffect } from 'react';
import { Footer, Header1, Navigation, MobileHeader, ReactToast, BottomTabs, Loder } from '../common';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const DesktopLayout = ({ children }) => {
    // useScrollTop();

    var location = useLocation();

    const sidebar = useSelector(state => state.masterdata.sidebar);
    const navigationstatus = useSelector(state => state.masterdata.NavigationStatus);
    console.log("navigationstatus", navigationstatus)

    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    return (
        <>
            {
                !isMobile ?
                    <>
                        {
                            sidebar &&
                            <div className='main-backdrop'></div>
                        }
                        {/* {
                            loaderstatus &&
                            <div className='main-backdrop main-backdrop-extra'></div>
                        } */}
                        <Header1 />
                        {
                            navigationstatus &&
                            navigationstatus.map((ele) => {
                                if (ele.component === "global-setting") {
                                    if (ele.sectionResult[0]?.left_navigation === "1") {
                                        return <Navigation />;
                                    }
                                }
                            })
                        }

                    </>
                    :
                    <>

                        <MobileHeader />

                        {/* {(location.pathname == '/' || location.pathname == '/my-account' || location.pathname == '/my-orders' || location.pathname == '/subscription') && <BottomTabs />} */}
                    </>

            }
            {children}
            <ReactToast />

            <Footer />
            <Loder />

        </>

    );
};

export default DesktopLayout;