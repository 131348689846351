import React, { useEffect, useContext, useState } from 'react';
import { ModalAddressListComp } from '../common';
import AddressAction from "../../redux/actions/AddressAction";
import { Container, Row, Col } from 'react-bootstrap';
import { HiOutlinePlusSm } from "react-icons/hi";
import Sheet from 'react-modal-sheet';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
const ModalAddressList = () => {
    const dispatch = useDispatch()
    const { getAddressList } = AddressAction();

    const addressList = useSelector(state => state.addressdata.addressList);


    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    useEffect(() => {
        dispatch({ type: 'setAddressopen', payload: 1 })
        dispatch(getAddressList())
    }, [])

    return (
        <div>
            <Container>
                <Row>

                    <Col lg={12} md={12}>
                        <NavLink to={"/addresses"} className="text-decoration-none1"
                            onClick={() => {
                                dispatch({ type: 'setopenaddressModal', payload: false })
                            }
                            }
                        >
                            <div className='accordion-deliver-viewmore mt-2 mb-2  contactAddressButton primaryColorcolor'>
                                <HiOutlinePlusSm color="#ff7e00" style={{ marginTop: "-3px" }} /> Add new Address
                            </div>
                        </NavLink>


                        <div className='question'>
                            <div className='custom-checkout-accordionbody'>
                                {

                                    addressList != undefined && addressList.length > 0 &&
                                    <ModalAddressListComp addressList={addressList} type={"fgfgf"} showbtn={true} />

                                }
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>

        </div>
    );
};

export default ModalAddressList;