import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import { Col } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

const HomepageCarousel = ({ slider_data,position }) => {
    console.log("slider_data",slider_data)
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });

    return (
        <div className="swiper-main mt-90">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                spaceBetween={20}
                loop={true}
                pagination={{ clickable: true }}
                effect="fade"
                autoplay={{ delay: 5000 }}
                navigation={{
                    nextEl: `.nextbtn${position}`,
                    prevEl: `.previousbtn${position}`,
                }}
                breakpoints={{
                    200: {
                        slidesPerView: 1,
                    },
                    700: {
                        slidesPerView: 1,
                    },
                    1800: {
                        slidesPerView: 1,
                    },
                }}
            >
                {slider_data && slider_data.sectionResult && slider_data.sectionResult.length > 0 &&
                    slider_data.sectionResult.map((Element, index) => (
                       
                        <SwiperSlide key={index}>
                            <div className="swiper-main">
                                <img
                                    referrerPolicy="no-referrer"
                                    className="home_carousel_img"
                                    src={isMobile ? Element.mobile_image : Element.image}
                                    // alt={Element.title}
                                />
                                {/* <div className="slider-progress"></div> */}
                                <Col lg={6} className='home-carousel-banner-content'>
                                    <div className='homebanner-title'>
                                        {Element.title}
                                    </div>
                                    <div className='homebanner-description'>
                                        {Element.description}
                                    </div>
                                    {Element.button_label && Element.button_label.length > 0 &&
                                        <div className='homebanner-button-slider'>
                                                <NavLink to={Element.url} className="text-decoration-none">
                                            <div className=' secondaryColorHover homebanner-cart-main-div'>
                                                {Element.button_label}
                                            </div>
                                            </NavLink>
                                        </div>
                                    }
                                </Col>
                            </div>
                          
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            {
                slider_data.sectionResult.length > 1 &&
                <div>
                <div className={`previousbtn previousbtn${position} secondaryColor primaryColorHover`}><BiSolidChevronLeft /></div>
                <div className={`nextbtn primaryColorHover secondaryColor nextbtn${position} `}><BiSolidChevronRight /></div>
            </div>
            }
       
        </div>
    );
}

export default HomepageCarousel;
