import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Contactformaddress from './Contactformaddress';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
function Modals() {
    const opencontact = useSelector(state => state.addressdata.opencontact);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch({
            type: 'setopencontact',
            payload: false
        })
    };
    return (
        <>

            <Modal show={opencontact} onHide={handleClose}>

                <Modal.Body>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Contactformaddress />
                </Modal.Body>

            </Modal>
        </>
    );
}

export default Modals;