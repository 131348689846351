import React from "react";
import { NavLink } from "react-router-dom";
import logo from '../../images/bg_image.webp';
const HomeImagecard = ({image,title,slug}) => {
    return (
        <div>
            <div class="single-categorie">
                <NavLink to={`/category/${slug}`}>
                <div class="cat-img">
                    {/* <a href="#"> */}
                   <img src={image}  className="image_aspect_ratio"
                     onError={e => {
                        e.currentTarget.src = logo
                      }}></img>
                    {/* </a> */}
                    <div class="cat-content ">
                        <div className="catcontent-titles primaryColorHover">{title}</div>
                    </div>
                </div>
                </NavLink>
            </div>
        </div>
    )
}
export default HomeImagecard;