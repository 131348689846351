import React, { useContext } from 'react'
import { useState } from 'react';
import { BsDash, BsPlus } from "react-icons/bs";
import { useCollapse } from 'react-collapsed'
import { useSearchParams, useParams,useLocation } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

const CheckBox = ({ item, type }) => {
  const dispatch = useDispatch()
  const checkItems = useSelector(state => state.catlogdetail.checkItems);
  const selectedItem = useSelector(state => state.catlogdetail.selectedItem);
  const categorylimit = useSelector(state => state.masterdata.categorylimit);
     const filterPage = useSelector(state => state.catlogdetail.filterPage);
     
  const [searchParams, setSearchParams] = useSearchParams();
  const { slug } = useParams()
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(
    {
      defaultExpanded: true
    }
  )
  const [searched, setSearched] = useState('');

  const handler = e => {
    setSearched(e.target.value)
  }

  const handelCheckBox = (e, data) => {
    window.scrollTo(0,0)
    // dispatch({ type: 'setFilterPage', payload: 1 });
    if (e.target.checked) {
      dispatch({ type: 'setCheckItems', payload: [...checkItems, e.target.value] })
      if (searchParams.has(data.title)) {

        const ParamsValue = searchParams.get(data.title);
     
        searchParams.delete(data.title);
        searchParams.set(
          data.title,
          ParamsValue + "," + e.target.value
        );
      } else {
        searchParams.set(data.title, e.target.value);
      }
    } else {
      const ParamsValue = searchParams.get(data.title);
      var paramsArray = ParamsValue.split(",");
      var filteredArray = paramsArray.filter(
        (ele) => ele !== e.target.value
      );
      searchParams.set(data.title, filteredArray.toString());
      if (filteredArray.length <= 0) {
        searchParams.delete(data.title);
      }
      dispatch({ type: 'setCheckItems', payload: checkItems.filter((each) => each !== e.target.value) })
    }
    setSearchParams(searchParams);

    var queryObj = {};
    for (const entry of searchParams.entries()) {
      queryObj = Object.fromEntries([...searchParams]);
    }

    var finalInput = Object.entries(queryObj).map(([key, value]) => ({
      title: key,
      value: value.split(",").map((ele) => ele.split("_")[0]),
    }))

    if (finalInput.length > 0) {
      var finaloutput = finalInput.flatMap(o => o.value);
      var input_data = {
        search: slug,
        "page": 1,
        "limit": localStorage.getItem("categorylimit"),
        "filter_update": finaloutput.length > 0 ? 1 : 1,
        "filter_attributes": finaloutput,
        "filter_categories":
          selectedItem != null ? [selectedItem.split("@")[1]] : []

      }
      // getFilterSearchResult(input_data)
      // setCheckItems(finaloutput)
    }

  };

  return (
    <div className='custom-border-bottom'>

      <div className="" {...getToggleProps()}>
        <button type="button" className="menuShow filterTitleContainer">
          <p className="filterTitle">{item.title}</p>
          <div>{isExpanded ? <BsDash /> : <BsPlus />}</div>
        </button>

      </div>

      <div className="mb-2 filterItem" {...getCollapseProps()}>

        <div className='filterItems'>

          <div className='search' style={{borderBottom:"unset"}}><input type='search' onChange={handler} value={searched} className="search" placeholder={` Search for ${item.title}`}></input></div>
          <div className={`parent ${item.list.length >= 5 ? "textLength textScroll" : ""}`}>

            <ul>
              {
                item.list.filter(ele => ele.hasOwnProperty('attr_value_name') && ele.attr_value_name.toLowerCase().includes(searched.toLowerCase())).sort((a, b) => a.attr_value_name.localeCompare(b.attr_value_name)).map((ele,index) => {
                  return (
                    <li key={index}>
                      <div className='list'>
                        <input type='checkbox' id={`inline-checkbox-${ele.attr_value_id}`} value={ele.attr_value_id}
                          checked={checkItems.includes(ele.attr_value_id)}
                          className="checkbox-custom"
                          name="checkeditem"
                          onChange={(e) => handelCheckBox(e, item)}
                        />
                        <label htmlFor={`inline-checkbox-${ele.attr_value_id}`}>{ele.attr_value_name}</label>
                      </div>
                    </li>
                  )
                })
              }

            </ul>

          </div>

        </div>
      </div>

    </div>
  )
}

export default CheckBox
