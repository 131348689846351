
import React from "react";
import Contactformaddress from "../components/common/Contactformaddress";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { SiMinutemailer } from "react-icons/si";
import { Row, Col, Container } from "react-bootstrap"
const Contactform = () => {
    return (
        <Container>
            <Row className="contact-page-main">
                <Col lg={6} md={6} sm={12} className="contact-page">
                    <div className="ot-heading">
                        <span className="is_highlight">contact details</span>
                        <h3 className="main-head">Our Contacts</h3>
                    </div>
                    <div className="space-20"></div>
                    <div className="space-2"></div>
                    <p>Give us a call or drop an email, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.</p>
                    <div className="space-10"></div>
                    <div class="box-s1 icon-left">
                        <div class="icon-box icon-box-2 contact-iconb">
                            <div class="icon-main">
                                <FaMapMarkerAlt size={25}  className='icon primaryColorcolor' />
                            </div>
                            <div class="content-box">
                                <h6 class="title-box">Visit Us Daily</h6>
                                {

                                    <p>{localStorage.getItem("domain_address")}</p>
                                }
                            </div>
                        </div>
                        <div class="space-30"></div>
                        <div class="icon-box icon-box-2 contact-iconb">
                            <div class="icon-main contact-icon-phone">
                                <FaPhoneAlt size={25}  className='icon primaryColorcolor' />
                            </div>
                            <div class="content-box">
                                <h6 class="title-box"><a href={`tel:+91-${localStorage.getItem("domain_whatsapp")}`}>Call Us 24/7</a></h6>
                                {

                                    <p>+91-{localStorage.getItem("domain_whatsapp")}</p>
                                }
                            </div>
                        </div>
                        <div class="space-30"></div>
                        <div class="icon-box icon-box-2 contact-iconb">
                            <div class="icon-main contact-icon-mail">
                                <SiMinutemailer size={25}  className='icon primaryColorcolor' />
                            </div>
                            <div class="content-box">
                                <h6 class="title-box"><a href={`mailto: ${localStorage.getItem("domain_email")}`}>Mail Us</a></h6>
                                {

                                    <p>{localStorage.getItem("domain_email")}</p>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} >
                <div className="contact-form-maindd">
                <Contactformaddress />
                </div>
                    
                </Col>
            </Row>
        </Container>



    )
}
export default Contactform;