import React from 'react';
import { NavLink } from 'react-router-dom';
import fav from '../../images/FAV.png';
import { FiShoppingCart } from "react-icons/fi";
const EmptyCart = () => {
    return (
        <div className='cartempty'>
            {/* <div><img src={fav}></img></div> */}
            <div><FiShoppingCart className='cart-empty-icon'/></div>
            <div className='cartempty-msg'>Your Cart is Empty!</div>

            <NavLink to="/" className="text-decoration-none">
                <div className='catrempty-btn primaryColor secondaryColorHover'>Continue shoping</div>
            </NavLink>
        </div>
    );
};

export default EmptyCart;