import React, { useContext } from 'react';
import { BsPlusCircle, BsDashCircle, BsTrash } from 'react-icons/bs'
import { BsPlus, BsDash } from 'react-icons/bs';
import { Subscription } from '../../views';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import CartAction from '../../redux/actions/CartAction';
import { NavLink } from 'react-router-dom';
import logo from '../../images/bg_image.webp';
const CartList = ({ type }) => {
    const dispatch = useDispatch()
    const { deleteCart, changeCartQuantity } = CartAction();
    const userData = useSelector(state => state.masterdata.userData);
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const openModal = useSelector(state => state.cartdetail.openModal);
    const masterData = useSelector(state => state.masterdata.masterData);

    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteCart(input_data))
    }

    const updateQuantity = (quantity, cart_id) => {
        const input_data = {
            cart_id: cart_id,
            quantity: quantity
        }
        dispatch(changeCartQuantity(input_data))
    }

    const handleClose = () => {
        dispatch({ type: 'setopenModal', payload: !openModal })
    }

    return (
        <>
            {
                cartDetail.result.filter(ele => ele.cart_status == 1).map(ele => {
                    return (

                        <div className="cart-list-detail" >

                            <div className='card-detail-firstrow'>
                                <div className='cart-detail-lefttside'>
                                    <NavLink to={`/product/${ele.slug}`}>
                                        <div className={`${type == 'checkout' ? "cehcekoutcartimg" : ""} cart-product-img`}>
                                            <img referrerPolicy="no-referrer" src={ele.img} 
                                            onError={e => {
                                                e.currentTarget.src = logo
                                                 }}
                                              
                                            />
                                        </div>
                                    </NavLink>

                                </div >

                                <div className='cart-detail-rightside'>
                                    <div>
                                        <div className='cart-product-detail'>
                                            <div className='product-title'>{ele.product_title}</div>
                                            <div className='product-variant_name'>{ele.variantname}</div>
                                            <div className='product-price'>
                                                <div className="product-price product-price-revamp">
                                                    <div className="Paragraph-16-M--Semibold">{localStorage.getItem("currency") + ele.price * ele.quantity}</div>
                                                    {
                                                        ele.price != ele.mrp &&
                                                        <span className="offer offer-revamp Paragraph-16-M--Regular">{localStorage.getItem("currency") + ele.mrp * ele.quantity}</span>
                                                    }
                                                </div>
                                                {
                                                    ele.price != ele.mrp &&
                                                    <div className="offer-label-revamp primaryColorcolor Paragraph-12-XS--Semibold">{ele.discount}% OFF</div>
                                                }
                                            </div>


                                        </div>
                                    </div>

                                    {/* {
                                        type != 'checkout' &&
                                        <div className='cart-corner-rightside'>

                                            <div
                                                className='cart-delivery-expected_by'
                                            >
                                                Delivery by 17th Aug
                                            </div>
                                        </div>
                                    } */}

                                </div>
                            </div>

                            {
                                type != 'checkout' &&
                                <div className='card-detail-secondrow'>
                                    <div className='card-detail-secondrow1 cart-w-15'>

                                        <div className='quantityContainer1'>
                                            <div
                                                className={`plusButton1 ${ele.quantity == '1' ? "swiper-button-disableds" : ""}`}
                                                onClick={() => updateQuantity(parseInt(ele.quantity) - 1, ele.id)}

                                            >
                                                <BsDash size="22" />
                                            </div>

                                            <div className="quantity1">
                                                {ele.quantity}
                                            </div>

                                            <div

                                                className="plusButton1"
                                                onClick={() => updateQuantity(parseInt(ele.quantity) + 1, ele.id)}

                                            >
                                                <BsPlus size="22" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className='card-detail-secondrow-rightside'>
                                        <div class="_3dsJAO" onClick={() => clicked('save', ele.id)}>Move To WishList</div>
                                        <div class="_3dsJAO" onClick={() => clicked('cart', ele.id)}>Remove</div>
                                    </div>
                                </div>
                            }


                        </div>


                    )
                })
            }


            {
                type != 'checkout' && (cartDetail.result.filter(ele => ele.cart_status == 4).length > 0 ? cartDetail.result.filter(ele => ele.cart_status == 4).map((element => {
                    return (
                        <div className='vip-membership-main-div'>
                            <div className='vip-membership-logo'><img className='vip-membership-logo-image' 
                                onError={e => {
                                    e.currentTarget.src = logo
                                  }}
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3DboFFQ04BBxPIavMs86F-OP5LPfWxoCYjg&usqp=CAU"></img></div>
                            <div className='vip-membership-content'>
                                <div className='vip-membership-content-status' >{element.subscription_name}</div>
                                <div className='vip-membership-content-price'>{localStorage.getItem("currency") + element.price}</div>
                                <div className='vip-membership-planchange'
                                    onClick={() => {
                                        dispatch({ type: 'setopenModal', payload: !openModal })

                                    }
                                    }>Change plan</div>
                            </div>
                            <div className='vip-membership-content-remove-button' onClick={() => clicked('cart', element.id)}>Remove</div>

                        </div>
                    )
                }))

                    :

                    (Object.keys(masterData).length > 0 && !masterData.hasOwnProperty("subscription") && userData.hasOwnProperty("cust_type") && userData.cust_type == 1) &&
                    <div className='savelater-image-subscription'
                        onClick={() =>
                            dispatch({ type: 'setopenModal', payload: !openModal })
                        }>
                            {/* <img referrerPolicy="no-referrer" className='savelater-image-subscription-image' src='https://tricksrecharge.com/wp-content/uploads/2021/02/IMG_20210222_115518-300x102.jpg'     onError={e => {
                            e.currentTarget.src = logo
                          }}></img> */}
                          </div>
                )}


            <Modal
                show={openModal}
                scrollable={true}
                size="lg"
                centered
                contentClassName="subscription-modal"
                onHide={() => handleClose()}
            >

                <Modal.Body>

                    <div className='subscription-cancel'>
                        <img referrerPolicy="no-referrer" className='subscription-cancel-icon'
                            onError={e => {
                                e.currentTarget.src = logo
                              }}
                            onClick={() => {
                                dispatch({ type: 'setopenModal', payload: !openModal })

                            }} src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHsAAAB7CAMAAABjGQ9NAAAASFBMVEX///8AAADh4eEYGBgbGxsWFhbc3NwTExPZ2dkNDQ0QEBAgICCXl5dSUlJpaWnp6en19fW0tLQrKyusrKzS0tJ9fX1KSkq8vLzujY/UAAADJElEQVRogcVbbZujIAwEbdFq37at7f//p+ftnfuIkhEk7OQjto6QIQkhMeZbXudLY/vr82ZKyu157W1zOb9mY+5tJxnuxZDvww/K202DX3Ymj6oQdPWYw3wFoK3tyoBXnQ/zDe7sQo4lwKt6CfN32d/LwRLga2j7Hhm+GrT21CpDt6cAysucA6P24LbflyDuEAI5m0to2Daa4O4YxLiYJjhuGz2dVxKECY8rEi5As/9i+sLgVXjBR+nNVXpkOw22t534/qt5is802B5m+D95mpv80Ha54EtD6snoMwfwOFPnsq5HGcYf3B/gB1ngMsNHedw3FybDyMBZT94Sfl+zl+2tYFIW6wnBd7IdMdxTZRXyM5Oc9ug84Y1t7FfGQsOVXKhR8DU7wTGDVkqErDym6VzyXAL0xrceU9jepq8hnHkC26MZ7oEjI2NjdQ4ZLgfgGmxPYvhcHJp5FDiExn4R6jzCtjtoSDf+n8f2TCuRw3bI8JjIFxoGaNshw+OcMWR7LYNX6H+I4XNx6PtFrUFlnaJN056Nkr89Y8CDbHfwH0l+EBKuXs9cEXpLe8uXQU+Q6IDNxl49+OAqDPfAo9kO18juCjUh4WabZt+WzAGvp6VUpdkMPMIr4bAw40i1faxRZvhcttiuznAPHBGp/4gZE5tiwyWBM0MCPF48ONy9ouikinDoLIhWNhruYGHWannJZPBaMRkMA4O15DN8LklsPyjp+gc8nu36qX8YAHsLXuDKI5JwgYDqt8CTkgQJEmFksjOwMvgW27UZ7oEjvzX6tYLQxnwg9qckNHHeRH0TeU7c30S7RrTnRD9G9N/EuIUYrxHjVGJ8TjyXEM9jxHMo8fwN8+Fl8w7EfAsxz0TMrxHzivD2oGw+lZhHJubPifcGxPsS4j1RCsM98Hy2E+8FifehxHvg3PvvGM8nfTfv3p9Y70Cs8yDWt+hVsSWzPatCYzmNtHomYh0XsX6NWLdHrFck1mniQG//aTKiPpVZl1uyHhkt+2CoddjM+nNm3T2z36AwNOyzYPaXMPtqmP1EzD4qZv8YtW+O2S9I7ZOk9ocaUl/sH6xbKvN6zKGKAAAAAElFTkSuQmCC'></img></div>
                    <Subscription customclass={"modal-subscription"} />
                </Modal.Body>
            </Modal>

        </>

    );
};

export default CartList;