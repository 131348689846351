import React from "react";
import { BlogPosts } from "../components/common";
import { Row, Col,Container } from "react-bootstrap";
const BlogList = () => {
    const fullcarouselBlogdata = [
        {
            "product_images": "https://templates.thememodern.com/maxbizz/images/blog/blog-1.jpg",
            "product_title": "Contactless Payments’ Time Has Come",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",
            "author_name": "srii"


        },
        {
            "product_images": "https://htmldemo.net/makali/makali/img/blog/blg2.webp",
            "product_title": "Contactless Payments’ Time Has Come",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",

            "author_name": "srii"
        },
        {
            "product_images": "https://templates.thememodern.com/maxbizz/images/blog/blog-1.jpg",
            "product_title": "Contactless Payments’ Time Has Come",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",
            "author_name": "srii"

        },
        {
            "product_images": "https://htmldemo.net/makali/makali/img/blog/blg2.webp",
            "product_title": "modern brush",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",
            "author_name": "srii"

        }


    ]
    return (
        <Container >
            <Row>
                {
                    fullcarouselBlogdata.map(ele => {
                        return (
                            <Col lg={4} md={6} className="Blogproduct">
                                <BlogPosts image={ele.product_images} title={ele.product_title} />
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>

    )
}
export default BlogList;