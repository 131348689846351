import React, { useState, useContext, useEffect } from 'react';
import {
    Form, Row
} from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { StateContext } from '../context/ManageStates';
import { AddressForm, MobileHeader1 } from '../common';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AddressAction from '../../redux/actions/AddressAction';

const AddressList = ({ addressList }) => {

    const dispatch = useDispatch()

    const [checked, setchecked] = useState();

    const { updateAddressfun,deleteAddress } = AddressAction();

    const location = useLocation();

    var pathname = location.pathname;

    const openform = useSelector(state => state.cartdetail.openform);
    const addressData = useSelector(state => state.addressdata.addressData);

    useEffect(() => {
        setchecked(addressList[0].addressId)
    }, [])


    const handleClick = (addressId) => {
        dispatch({ type: 'setopenform', payload: false })
        setchecked(addressId)
    }


    const handleEdit = (addressId) => {
        dispatch({ type: 'setAddressopen', payload: 3 })
        dispatch({ type: 'setaddressData', payload: addressList.filter(ele => ele.addressId == addressId) })
        dispatch({ type: 'setAddres', payload:'' })
        setchecked(addressId)
    }

    const handleUpdateAddress = (addressId) => {
        setchecked(addressId)
        const input_data = {
            "default": "1",
            "addressId": addressId
        }
        dispatch(updateAddressfun(input_data))
    }

    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteAddress(input_data))
    }

    return (
        <>
            {
                addressList.filter(elefilter => elefilter.addresstype == 'Delivery').map(ele => {
                    return (
                        <>
                            {
                                (addressData.length > 0 && openform && addressData[0].addressId == ele.addressId) ?
                                    <>
                                        <AddressForm addressData={addressData} />
                                        <div className='border-bottom'></div>
                                    </>
                                    :
                                    <div className='accordion-address-body-listitem' >
                                        <div className="d-flex justify-content-between" >
                                            <div onClick={() => handleClick(ele.addressId)}>
                                                <Form.Check
                                                    type="radio"
                                                    name="addressType"
                                                    value={ele.addressId}
                                                    label={ele.name}
                                                    onChange={(e) => setchecked(e.target.value)}
                                                    checked={checked == ele.addressId}
                                                    // defaultChecked={each.default_address ? true : false}
                                                    aria-label="radio 1"
                                                />
                                            </div>
                                            <div className="d-flex justify-content-end addressIcon">
                                                <BiPencil
                                                    size={20}
                                                    className="me-1"
                                                    onClick={() => handleEdit(ele.addressId)}
                                                />
                                                <AiOutlineDelete
                                                    color=""
                                                    size={20}
                                                    onClick={(e) => clicked('address', ele.addressId)}
                                                />
                                            </div>
                                        </div>
                                        <div className='checkout-list-address'>
                                            {ele.address1}<br/>
                                            {ele.address2}
                                            <div>Hyderabad, {ele.state} - <span>{ele.pincode}</span></div>
                                        </div>
                                        {
                                            (checked == ele.addressId && pathname != '/addresses') &&
                                            <div className='product-detail-buttongroup deliver-address-btn' onClick={() => handleUpdateAddress(ele.addressId)}>
                                                <div className={`product-detail-button primaryColor`}>
                                                    DELIVER HERE
                                                </div>
                                            </div>
                                        }
                                    </div>
                            }

                            <Row>
                                <div className='buttons-product-detaildiv custom-addresslist-bottom-btn' onClick={() => {
                                    dispatch({ type: 'setAddressopen', payload: 2 })
                                    dispatch({ type: 'setAddres', payload: '' })
                                    dispatch({ type: 'setaddressData', payload: [] })
                                    dispatch({
                                        type: 'setFormValues', payload: {
                                            name: "",
                                            phone: "",
                                            address1: "",
                                            address2: "",
                                            city: "",
                                            country: "",
                                            pincode: "",
                                            state: "",
                                            addressId: "0",
                                            default: 1,
                                            addresstype: "Delivery"
                                        }
                                    })

                                }}>
                                    <div className='product-detail-buttongroup'>
                                        <div className='product-detail-button primaryColor text-decoration-none primaryColor'>
                                            Add Address
                                        </div>
                                    </div>
                                </div>

                            </Row>

                        </>
                    )
                })
            }
        </>

    );
};

export default AddressList;