import React, { useEffect, useState } from 'react';
import logo from '../../images/bg_image.webp';
import { useDispatch } from 'react-redux';

const Addons = ({ data }) => {
    const dispatch = useDispatch();
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [selectitem, setSelectitem] = useState([]);


    // Function to handle selection of addons
    const handleSelectClick = (addonid) => {
        const selectedAddon = data.find(ele => ele.addonid === addonid);
        if (selectedAddon) {
            if (selectedAddons.includes(selectedAddon)) {
                // If the addon is already selected, remove it and its related products
                setSelectedAddons(selectedAddons.filter(ele => ele.addonid !== addonid));
                setSelectitem(selectitem.filter(item => !selectedAddon.addon_products.includes(item)));
            } else {
                // Add the selected addon if not already in the list
                setSelectedAddons([...selectedAddons, selectedAddon]);
            }
        }
    };

    // Function to handle selection of addon products
    const Addonitem = (item, productid) => {
        if (selectedAddons.some(ele => ele.addon_products.includes(item))){
            if (selectitem.includes(item)){
                // If the product is already selected, remove it
                setSelectitem(selectitem.filter(ele => ele.productid !== productid));
            } else {
                // Add the selected product if not already in the list
                setSelectitem([...selectitem, item]);
            }
        }
    };

    // Use effect to dispatch the selected items
    useEffect(() => {
        dispatch({
            type: "Setproductdata",
            payload: selectitem
        });
    }, [selectitem]);

    return (
        <div>
            <div className='Addon-main-title'>Addons List</div>
            <div className='Addon-names'>
                {data.map(ele => (
                    <div
                        key={ele.addonid}
                        onClick={() => handleSelectClick(ele.addonid)}
                        style={{ cursor: 'pointer' }}
                        className={`Addons-title ${selectedAddons.includes(ele) ? 'primaryColor' : 'addon-before'}`}
                    >
                        <div>{ele.addon_name}</div>
                    </div>
                ))}
            </div>
            <div>
                {selectedAddons.map(ele => (
                    <div key={ele.addonid}>
                        <div className='Addon-main-title'>{ele.addon_name}</div>
                        <div className='Addon-images'>
                            {ele.addon_products.map(item => (
                                <div
                                    key={item.productid}
                                    className={`Addon-image-div ${selectitem.includes(item) && "imageborder"}`}
                                    onClick={() => Addonitem(item, item.productid)}
                                >
                                    <img
                                        className='image-addon'
                                        src={localStorage.getItem("cdn") + item.product_images}
                                        // onError={e => e.currentTarget.src = logo}
                                        // alt={item.product_name}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Addons;
