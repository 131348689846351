import React, { useState, useEffect } from 'react';
import { Container, Row } from "react-bootstrap"
import { FaPlus } from "react-icons/fa6";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import logo from '../../images/bg_image.webp';
const CrosSellListMobile = ({ data }) => {
    const [selectedItems, setSelectedItems] = useState(data.map(() => true));
    const [totalPrice, setTotalPrice] = useState(0);
    const [plus, setPlus] = useState();
    const [activeCheckboxCount, setActiveCheckboxCount] = useState(data.length);
    const [openall, setOpenall] = useState(true)
    useEffect(() => {
        calculateTotalPrice(selectedItems);
    }, [selectedItems]);

    const calculateTotalPrice = (items) => {
        let updatedTotalPrice = 0;
        let activeCheckboxes = 0;

        items.forEach((isChecked, index) => {
            if (isChecked) {
                updatedTotalPrice += parseInt(data[index].selling_price, 10);
                activeCheckboxes++;
            }
        });

        setTotalPrice(updatedTotalPrice);
        setActiveCheckboxCount(activeCheckboxes);
    };

    const handleItemClick = (e,index) => {
        
        const updatedItems = [...selectedItems];
        updatedItems[index] = !updatedItems[index];
        setSelectedItems(updatedItems);
        setPlus(index);
    };

    return (
        <div className='ProductsboughtMobile'>
         
<div className='ProductsboughtMobile-images'>
    {data.map((item, index) => (
        selectedItems[index] && (
            <div key={index}>
                <div className='ProductsboughtMobile-images-sub-div'>
                    <img referrerPolicy="no-referrer" className='productmobile-bought-image-imagesall' src={item.product_images} alt={item.title} 
                        onError={e => {
                            e.currentTarget.src = logo
                          }}/>
                    {index < data.length - 1 && selectedItems.slice(index + 1).some(item => item) && (
                        <FaPlus className='product-mobile-bought-icon' />
                    )}
                </div>
            </div>
        )
    ))}
</div>
            <>
                {
                    openall ?
                        <div onClick={() => setOpenall(false)}>
                            <div className='productbought-mobile-inital'>
                            <span className='product-boudht-price-title-div'>   Buy all {activeCheckboxCount} products :</span><span className='product-boudht-price-div'> {localStorage.getItem("currency") + totalPrice}</span>
                                
                                <IoIosArrowDown className='productbought-mobile-downarrow' />
                            </div>


                        </div>
                        :
                        <div>
                            <div>
                                {data.map((item, index) => (
                                    <div key={index} >
                                        <div className='productbought-mobile-main-main-div '>
                                            <div className='productbought-mobile-checkbox-main-div'>
                                                <input className='productbought-mobile-checkbox'
                                                    type="checkbox"
                                                    checked={selectedItems[index]}
                                                    onChange={(e) => handleItemClick(e,index)}
                                                />
                                            </div>
                                            <div className={`home-product-card  productbought-mobile-main-title-price-main`}>
                                                <h5 className='home-product-card-title productbought-mobile-title-images-title '>{item.title}</h5>
                                                <div className="productbought-mobile-title-images-price">{localStorage.getItem("currency") + item.selling_price}</div>

                                            </div>


                                        </div>

                                    </div>
                                ))}
                            </div>
                            <div>
                                <div className='product-bought-output productbought-mobile-output '>



                                    <div className="product-price "><div className="product-price product-price-revamp"><div className="Paragraph-16-M--Semibold">  <span className='product-boudht-mobile-price-title-div'>  Total Price:</span><span className='product-boudht-mobile-price-div'> {localStorage.getItem("currency") + totalPrice}</span></div></div></div>

                                    <div className='product-detail-buttongroup product-detal-cart_btn  productbought-mobile-total-price '>

                                        <div className={`product-detail-button primaryColor cart-btn product-bought-mobile-button  `}>
                                            <RiShoppingCart2Fill className="product-detail-btn-icon " />
                                            Add all {activeCheckboxCount} to cart
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                }
            </>

        </div>
    );
};




export default CrosSellListMobile;