// ReviewRatingSection.js
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Col, Row } from 'react-bootstrap';
import { CgProfile } from "react-icons/cg";
import { useMediaQuery } from "react-responsive";
import { BiSolidChevronRight, BiSolidChevronLeft } from "react-icons/bi";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Progressbar } from '../common';
import { FaStar } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import logo from '../../images/bg_image.webp'; // Adjust the import path based on your file structure
import ImageModal from './ImageModal'; // Adjust the import path based on your file structure

const ReviewRatingSection = ({ position, carouselCompData, loopindex, type, link }) => {
    const [my_swiper, set_my_swiper] = useState({});
    const reviewdata = useSelector(state => state.cartdetail.reviewdetails);
    const ratingdata = reviewdata.map(ele => ele.rating);
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    const [index, setindex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalImages, setModalImages] = useState([]);
    const [initialSlideIndex, setInitialSlideIndex] = useState(0);
   
    const allImages = reviewdata.flatMap(review => review.image);
    useEffect(() => {
        const id = setInterval(() => {
            setProgress(Math.random() * 100);
        }, 3000);
        return () => {
            clearInterval(id);
        };
    }, []);

    const handleImageClick = (images, index) => {
        setModalImages(images);
        setInitialSlideIndex(index);
        setShowModal(true);
    };

    const slidechange = (e) => {
        setindex(e.realIndex);
    };

    const totalRatings = reviewdata.filter((ele) => ele.rating).length;
    const totalreview = reviewdata.filter((ele) => ele.review).length;
    const starRatings1 = reviewdata.filter((ele) => ele.rating === 1).length;
    const starRatings2 = reviewdata.filter((ele) => ele.rating === 2).length;
    const starRatings3 = reviewdata.filter((ele) => ele.rating === 3).length;
    const starRatings4 = reviewdata.filter((ele) => ele.rating === 4).length;
    const starRatings5 = reviewdata.filter((ele) => ele.rating === 5).length;

    const percentage1 = (100 * starRatings1) / totalRatings;
    const percentage2 = (100 * starRatings2) / totalRatings;
    const percentage3 = (100 * starRatings3) / totalRatings;
    const percentage4 = (100 * starRatings4) / totalRatings;
    const percentage5 = (100 * starRatings5) / totalRatings;

    let sum = 0;
    ratingdata.forEach((star) => {
        sum += star;
    });

    const starsavg = sum / totalRatings;

    return (
        reviewdata.length > 0 && (
            <>
                {/* Modal Component */}
                <ImageModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    images={modalImages}
                    initialIndex={initialSlideIndex}
                />

                <div className={`home-carousel-component-header reviewtitle-heading ${link === 'product-detail' ? 'detail-carousel-bottom' : ''}`}>
                    <div className='Quality-products'>
                        <h5 className='Quality-products-details-left2'>
                            Customer Ratings & Reviews
                        </h5>
                    </div>
                </div>
                <Row className='review-and-rating'>
                    <Col lg={4}>
                        <div className='ratedProduct-rating'>
                            {[...Array(5)].map((_, i) => {
                                const ratingValue = i + 1;
                                return (
                                    <label key={i}>
                                        <input
                                            type="radio"
                                            name="rating"
                                            value={ratingValue}
                                            style={{ display: 'none' }}
                                        />
                                        <FaStar
                                            className={ratingValue <= starsavg ? 'primaryColorcolor' : "unselected_value"}
                                            size={20}
                                        />
                                    </label>
                                );
                            })}
                            <span className='rating_summary'>{starsavg.toFixed(1)} Out of 5</span>
                            <div className='ratings'>
                                <div className='rating-count' style={{ paddingLeft: "3px" }}>
                                    <span>{totalRatings} Ratings </span>
                                    <span>& </span>
                                    <span> {totalreview} Reviews</span>
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <Progressbar value={percentage5} star={5} />
                            <Progressbar value={percentage4} star={4} />
                            <Progressbar value={percentage3} star={3} />
                            <Progressbar value={percentage2} star={2} />
                            <Progressbar value={percentage1} star={1} />
                        </div>
                    </Col>
                    <Col lg={8}>
                    <div className='reviewimagestitle'>Images uploaded by customers:</div>
                        <div className="swiper-main reviewimages">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={20}
                                effect="cards"
                                navigation={{
                                    nextEl: `.nextbtn${position}`,
                                    prevEl: `.previousbtn${position}`,
                                }}
                                breakpoints={{
                                    200: { slidesPerView: 3.5 },
                                    400: { slidesPerView: 3.5 },
                                    700: { slidesPerView: 6 },
                                    750: { slidesPerView: 8 },
                                    1000: { slidesPerView: 10 },
                                    1200: { slidesPerView: 10 },
                                    1400: { slidesPerView: 10 },
                                    1600: { slidesPerView: 10 },
                                    1800: { slidesPerView: 10 },
                                }}
                            >
                                {/* Display images in Swiper */}

                              {
                                allImages.map((ele2, index2) => {
                                      

                                        return(
                                            <SwiperSlide key={index2}>
                                            <img
                                                className='rating-reviews-allimages'
                                                referrerPolicy="no-referrer"
                                                src={localStorage.getItem("cdn") + "/" + ele2}
                                                onError={(e) => e.currentTarget.src = logo}
                                                onClick={() => handleImageClick(allImages, index2)} // Open modal on click
                                            />
                                        </SwiperSlide>
                                        )
                                    }

                                        
                                    
                                    
                                )}
                            
                            </Swiper>
                            <div>
                                <div className={`previousbtn primaryColorHover secondaryColor previousbtn20}`}><BiSolidChevronLeft /></div>
                                <div className={`nextbtn primaryColorHover secondaryColor nextbtn20}`}><BiSolidChevronRight /></div>
                            </div>
                        </div>

                        {/* Display individual reviews and images */}
                        {reviewdata.map((ele, index) => (
                            <div key={index} className='ratings_container'>
                                <div className='ratings_user_headr'>
                                    <div>
                                    <CgProfile />
                                    </div>
                                    <div className='ratings_user_title'>{ele.customer_name}</div>
                                    <div className='ratindandreviewdate'>({ele.rating_date})</div>
                                </div>
                                <div className='ratedProduct-rating'>
                                    {[...Array(5)].map((_, i) => {
                                        const ratingValue = i + 1;
                                        return (
                                            <label key={i}>
                                                <input
                                                    type="radio"
                                                    name="rating"
                                                    value={ratingValue}
                                                    style={{ display: 'none' }}
                                                />
                                                <FaStar
                                                    className={ratingValue <= ele.rating ? 'primaryColorcolor' : "unselected_value"}
                                                    size={15}
                                                />
                                            </label>
                                        );
                                    })}
                                </div>
                                <div className='ratings_user_description'>
                                    {ele.review}
                                </div>
                                {ele.image.map((item, imgIndex) => (
                                    <img
                                        key={imgIndex}
                                        className='Ratingimages'
                                        src={localStorage.getItem("cdn") + "/" + item}
                                        alt={`Review ${index}`}
                                        onClick={() => handleImageClick(ele.image, imgIndex)} // Open modal on click
                                    />
                                ))}
                            </div>
                        ))}
                    </Col>
                </Row>
            </>
        )
    );
};

export default ReviewRatingSection;
