import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { FaSearch } from 'react-icons/fa'; // Import the search icon from react-icons
import SearchAction from '../../redux/actions/SearchAction';
import { useNavigate } from 'react-router-dom';
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/bg_image.webp';
const SearchForm = () => {
    const dispatch =useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const searchDetail = useSelector(state =>state.catlogdetail.searchDetail);
     const {getSearchProduct} =SearchAction();
     useEffect(() => {
        if (searchTerm.length > 0) {
            const input_data = {
                search: searchTerm
            };
            dispatch(getSearchProduct(input_data));
        }
    }, [searchTerm]);
    
    
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
  
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        navigate(`/search-result/${searchTerm}`);
    };
    const handleBackClick = () => {
        navigate(-1);
    };


    return (
        <div className="" style={{ display: 'block' }}>
            <div className= { searchDetail.length >0 ?'searchbar-main-comp': "search-main-data"}>
            <form  onSubmit={handleSubmit}>
                <div className="input-group search-comp-main-searchbar">
                    <input 
                        type="text" 
                        className="form-control search-wid" 
                        placeholder="Search Here" 
                        aria-describedby="basic-addon2"
                        value={searchTerm}
                        onChange={handleChange}
                    />
                    <button 
                        className="input-group-addon primaryColor btn-side-search" 
                        id="basic-addon2"
                        type="submit"
                    >
                        <FaSearch   className=' fa fa-search'/>
                    </button>
                    {/* <NavLink   className="text-decoration-none" to={`/`}> */}

                    <IoCloseSharp  className='search-closeicon' onClick={handleBackClick} />
                    {/* </NavLink> */}
                </div>
                
            </form>
            </div>
       {
        searchDetail.length >0  ?
        <div className='searchcomp-search'>
        {
            searchDetail.map(ele=>{
                return(
                    <NavLink   className="text-decoration-none" to={`/product/${ele.slug}`}>
                    <div className='search-cpomp-searchlist'>
                    <img className='search-images' referrerPolicy="no-referrer" src={ele.product_images[0]}
                       onError={e => {
                        e.currentTarget.src = logo
                      }}
                    ></img>
               
                    <h6 className='search-result-title'>{ele.product_title}</h6>
                </div>
                </NavLink>
                )
            })
        }

    </div> : ""
                        // <div className="searchbar-product-list">No Record Found</div>
       }
          
           
        </div>
    );
}

export default SearchForm;
