import React from "react";
import { Container, Row,Col } from "react-bootstrap";
const BlogDetails = () => {
    return (
        <>
        <div className="Blogdetails-page">
            <img className="blogdetail-image" src="https://kangaroomommy.in/assets/1722344561.webp?id=1725603403"></img>
            <Container fluid>
                <Row className="Blog-pagecontent">
                    <h1 className="Blogcontent">
                        Blog Details
                    </h1>
                </Row>
            </Container>
        </div>
        <Row>
            <Col>
            </Col>
        </Row>
        </>
    )
}
export default BlogDetails;