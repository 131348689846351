import React, { useState, useEffect } from 'react';
import { IoChevronDown, IoChevronUp, IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { CSSTransition } from 'react-transition-group';
import CartAction from '../../redux/actions/CartAction';
import { useSearchParams } from 'react-router-dom';
const Varientdropdown = ({ variantList }) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { addToWishList } = CartAction(searchParams);
    const { addToCartProduct } = CartAction();
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const productvarientdata = useSelector(state => state.masterdata.productvarientdata);
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });


    const clickwishlist = (id, type) => {

        dispatch(addToWishList(id, type))
    }
    const handleAddToCart = (Productid,Varientid) => {
        if (!authStatus) {
            const randomId = Math.floor(Math.random() * 100000000);
            if (localStorage.getItem("sessionId") === null) {
                localStorage.setItem('sessionId', randomId);
            }
        }
        const inputData = {
           "variantid":Varientid,
            "product_id": Productid,
            "quantity": 1,
            "type": 'product',
            ...(authStatus === false && { "session_id": localStorage.getItem("sessionId") })
        };
        dispatch(addToCartProduct(inputData));


    };
    useEffect(() => {
        if (variantList && variantList.length > 0) {
            dispatch({
                type: 'setproductvarientdata',
                payload: variantList[0]
            });
        }
    }, [dispatch, variantList]);

    const [isSheetOpen, setSheetOpen] = useState(false);

    const handleToggle = () => {
        const newState = !isSheetOpen;
        setSheetOpen(newState);
        toggleBodyScroll(newState);
    };

    const handleClose = () => {
        setSheetOpen(false);
        toggleBodyScroll(false);
    };

    const toggleBodyScroll = (enable) => {
        if (enable) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    };

    const selectedVariant = (id) => {
        const filteredVariant = variantList.find(ele => ele.variantid === id);
        if (filteredVariant) {
            dispatch({
                type: 'setproductvarientdata',
                payload: filteredVariant
            });
        }
        handleClose(); // Close the sheet after selection
    };

    const caretIcon = isSheetOpen ? (
        <IoChevronUp className='varinat-caret-icon' />
    ) : (
        <IoChevronDown className='varinat-caret-icon' />
    );

    return (
        <div className='product-card-variant-dropdown'>
            <div
                className={`product-card-selected-variant ${isSheetOpen && "selected"}`}
                onClick={handleToggle}
            >
                {productvarientdata.variantname}
                {caretIcon}
            </div>

            {isSheetOpen && <div className="background-overlay show" onClick={handleClose}></div>}

            <CSSTransition
                in={isSheetOpen}
                timeout={300}
                classNames="slide-up"
                unmountOnExit
            >
                <div className="sheet-container">
                <div className='mobile-varient-title'>
                    Select Varient
                    </div>
                    
                    <div className="close-icon-container">

                        <IoClose className="close-icon" onClick={handleClose} />
                    </div>
                    
                    <div className='product-card-variant-dropdownlist'>
                        {variantList.map(variant => (
                            <div
                                key={variant.variantid}
                                className={`product-card-variant-listitem ${productvarientdata && productvarientdata.variantid === variant.variantid ? "selected_variant_active" : ""}`}
                                onClick={() => selectedVariant(variant.variantid)}
                            >
                                  <div>
                                <div className='variant_title'>{variant.variantname}</div>
                              
                                <div className='product-price Varient-details'>
                                {
                                      variant.rstatus == 1 ?
                                      <div className=''>
                                      <div className="product-price product-price-revamp">
                                            
                                            <h5 className="Paragraph-16-M--Semibold varientsellingprice">{localStorage.getItem("currency") + variant.selling_price}</h5>
                                            {variant.mrp !== variant.selling_price && (
                                                <h5 className="offer offer-revamp primaryColorcolor varient-mrp Paragraph-16-M--Regular">{localStorage.getItem("currency") + variant.mrp}</h5>
                                            )}
                                               {variant.mrp !== variant.selling_price && (
                                            <h5 className="offer-label-revamp primaryColor Paragraph-12-XS--Semibold">
                                                {Math.round(100 - (variant.selling_price/variant.mrp ) * 100)}% OFF
                                            </h5>
                                        )}  
                                        </div>
                                      
                                              </div> :
                                                <div className='varientsellingprice'> Out Of Stock</div>
                                }
                               
                                     
                                  
                                    {
                                       
                                        variant.rstatus ==1 ?
                                        <div className='varientaddtocart primaryColor' onClick={()=>handleAddToCart(variant.productid,variant.variantid)}>Add</div>:
                                        (
                                            authStatus ?
                                                <div className='varientaddtocart primaryColor' onClick={() => clickwishlist(variant.productid, 'product')}>Wishlist</div> :
                                                <NavLink to={"/login"} className="text-decoration-none">
                                                    <div className='varientaddtocart primaryColor'>Wishlist</div>
                                                </NavLink>


                                        )
                                    
                                       
                                    
                                    }
                                     
                                </div>
                            </div>
                            </div>
                        ))}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

export default Varientdropdown;
