import React, { useContext, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import logo from '../../images/bg_image.webp';
const Loder = () => {

    const loaderstatus = useSelector(state => state.masterdata.loaderstatus);
    const loaderstatus1 = useSelector(state => state.masterdata.loaderstatus1);

    useEffect(() => {
        // if (loaderstatus == true || loaderstatus1 == true) {
        //     document.body.style.overflow = "hidden"
        // } else {
        //     document.body.style.overflow = "auto"
        // }
    }, [loaderstatus,loaderstatus1])
    return (
        <>
            {
                (loaderstatus || loaderstatus1) &&
                <div className="preloader primaryColorcolor">
                    <div>
                        {/* Background Image */}
                        {/* <img 
                        referrerPolicy="no-referrer"
                            src={localStorage.getItem("favicon") != '' && localStorage.getItem("favicon")}
                            alt="Background"
                            style={{ width: '25px', height: '25px', position: "relative", top: "-4px", left: "0px", objectFit: 'cover' }}
                            onError={e => {
                                e.currentTarget.src = logo
                              }}
                       /> */}
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            {/* <ClipLoader className="cart-btn-loader" loading={true} size={50} /> */}
                            <Spinner animation="border" role="status" className='custom-spinner' />
                        </div>
                    </div>

                </div>
            }
        </>


    );
};

export default Loder;