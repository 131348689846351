import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import 'swiper/css/pagination';
import Cardproduct from "./Cardproduct";
import HomeImagecard from "./HomeImagecard"
import HeadingTitle from "./HeadingTitle";
import BlogPosts from "./BlogPosts";

const Blogs = ({ position, fullcarouselBlogdata, Blogheading, blogdiscription }) => {


    const Blogdata = [
        {
            "image": "https://htmldemo.net/makali/makali/img/blog/blg4.webp",
            "title": "modern brush",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",
            "author_name": "srii"


        },
        {
            "image": "https://htmldemo.net/makali/makali/img/blog/blg2.webp",
            "title": "modern brush",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",

            "author_name": "srii"
        },
        {
            "image": "https://htmldemo.net/makali/makali/img/blog/blg3.webp",
            "title": "modern brush",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",
            "author_name": "srii"

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/blog/blg2.webp",
            "title": "modern brush",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",
            "author_name": "srii"

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/blog/blg3.webp",
            "title": "modern brush",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",
            "author_name": "srii"
        },
        {
            "image": "https://htmldemo.net/makali/makali/img/blog/blg2.webp",
            "title": "modern brush",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",
            "author_name": "srii"

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/blog/blg3.webp",
            "title": "modern brush",
            "date": "28-06-2024",
            "discription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. LoremIpsum has the industrys standard dummy text ever since r",
            "author_name": "srii"

        },

    ]

    return (
        <>
            {


                <HeadingTitle className={"center"} HeadingTitle={Blogheading} content={blogdiscription} />
            }
            <div className="swiper-main">

                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={20}
                    effect="cards"
                    // loop={true}
                    className=""
                    navigation={{
                        nextEl: `.nextbtn${position}`,
                        prevEl: `.previousbtn${position}`,
                    }}
                    breakpoints={{
                        200: {
                            slidesPerView: 1.5,
                        },
                        500: {
                            slidesPerView: 1.5,
                        },
                        700: {
                            slidesPerView: 2,
                        },
                        750: {
                            slidesPerView: 2,
                        },
                        1000: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 3,
                        },
                        1400: {
                            slidesPerView: 3,
                        },
                        1600: {
                            slidesPerView: 3,
                        },
                        1800: {
                            slidesPerView: 3,
                        },
                    }}
                // className={`mycategorySwiper${1}`}

                >
                    <div className='space-bottom-20'>

                        return (

                        {

                            fullcarouselBlogdata.map(ele => {
                                return (
                                    <SwiperSlide >
                                        <BlogPosts image={ele.product_images[0]} title={ele.product_title} discription={ele.discription} published_date={ele.date} author_name={"sri"} />
                                    </SwiperSlide>
                                )
                            })
                        }
                        )

                    </div>

                </Swiper>
                <div >
                    <div className={`previousbtn primaryColorHover secondaryColor previousbtn${position} `}><BiSolidChevronLeft /></div>
                    <div className={`nextbtn primaryColorHover secondaryColor nextbtn${position} `}><BiSolidChevronRight /></div>
                </div>
            </div>
        </>
    )
}
export default Blogs;