// ImageModal.js
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import logo from '../../images/bg_image.webp'; // Adjust the import path based on your file structure

const ImageModal = ({ show, onHide, images, initialIndex }) => {
    return (
        <Modal  show={show} onHide={onHide} size="md" centered>
            <div >
            <Modal.Header closeButton className='rating-and-review'>
                <Modal.Title>Rating & Reviews Images</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Swiper
                    modules={[Navigation, Pagination, A11y]}
                    spaceBetween={10}
                    slidesPerView={1}
                    initialSlide={initialIndex}
                    navigation
                    pagination={{ clickable: true }}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index} className='Related-data'>
                            <img
                                src={localStorage.getItem("cdn") + "/" + image}
                                onError={(e) => (e.currentTarget.src = logo)}
                                alt={`Slide ${index}`}
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Modal.Body>
            </div>
        </Modal>
    );
};

export default ImageModal;
