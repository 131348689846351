import { useState, useEffect } from 'react';
const useFormvalidation = (callback, validate, formValues) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [imgData, setImgData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        setValues(formValues);
    }, [formValues]);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);
    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };
    
    const handleChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    };

    const handlePictureChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.files[0] }));
        setImgData({
            ...imgData,
            [event.target.name]: URL.createObjectURL(event.target.files[0]),
        });
    };
    return {
        handleChange,
        handlePictureChange,
        handleSubmit,
        imgData,
        values,
        setErrors,
        errors,
    }
};
export default useFormvalidation;