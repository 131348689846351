import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useCollapse } from "react-collapsed";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../images/bg_image.webp';
import { useSearchParams, useParams } from "react-router-dom";

const ProductVariants = ({ item, countindex }) => {

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
        defaultExpanded: true,
    });

    const dispatch = useDispatch()

    const [sel, setsel] = useState([]);
    const [sel1, setsel1] = useState([]);

    const [selectedattr, setselectedattr] = useState(undefined);

    const productDetailData = useSelector(state => state.productdetail.productDetailData);
    const sel2 = useSelector(state => state.productdetail.sel2);

    const [searchParams, setSearchParams] = useSearchParams();

    const handleClick = (name, id) => {
        // if (sel1.length > 0 && selectedattr != undefined && sel1.filter(yui => yui.attribute_name != name).length > 0) {
        //     if (selectedattr.includes(id)) {

        //     } else {
        //         
        //     }
        // }




        setsel1([{
            attribute_name: name,
            attr_value_id: id
        }])

        // Check if an object with the same name already exists in the array
        const index = sel1.findIndex(item1 => item1.attribute_name == item.attribute_name);

        // setsel1([{
        //     attribute_name: name,
        //     attr_value_id: id
        // }])

        if (index !== -1) {
            // If the object exists, update it


            const updatedSel = [...sel2];
            updatedSel[index] = {
                ...updatedSel[index],
                attribute_name: item.attribute_name,
                attr_value_id: id
            };

            dispatch({ type: 'setsel2', payload: updatedSel })



        } else {

            dispatch({
                type: 'setsel2', payload: [...sel2, {
                    attribute_name: item.attribute_name,
                    attr_value_id: id
                }]
            })


        }







        // var final_data = productDetailData[0].variantList.filter(ele => ele.attributevalues_ids == ParamsValue);



    }
    const { slug } = useParams();


    useEffect(() => {
        setselectedattr(undefined)
    }, [slug])


    useEffect(() => {
        if (productDetailData.length > 0) {
            if (searchParams.has("variant")) {
                var final_data = searchParams.get("variant");
                var pagelodvarinat_Data = productDetailData[0].variantList.find(iop => iop.variantid === final_data);

                if (pagelodvarinat_Data) {
                    setsel({
                        attr_value_id: pagelodvarinat_Data.attributevalues_ids.split(",")[countindex]
                    });
                }
            } else {
                // if (productDetailData[0].slug === slug) {
                //   searchParams.set("variant", productDetailData[0].variantList[0].variantid);

                //   if (productDetailData[0].attributes.filter(yui => yui.variant_flag === 1).length > 1) {
                //     setsel({
                //       attr_value_id: productDetailData[0].variantList[0].attributevalues_ids.split(",")[countindex]
                //     });
                //   } else {
                //     setsel({
                //       attr_value_id: productDetailData[0].variantList[0].attributevalues_ids
                //     });
                //   }

                // //   setSearchParams(searchParams);
                // }
            }
        }
    }, [productDetailData, slug, countindex, searchParams, setSearchParams]);


    useEffect(() => {
        if (productDetailData.length > 0) {
            var ty = productDetailData[0].variantList.map(ele => {
                var ui = ele.attributevalues_ids.split(",")
                const isAnyNamePresent = ui.some(targetName =>
                    sel1.some(obj => obj.attr_value_id === targetName)
                );



                if (isAnyNamePresent) {

                    if (sel1.filter(elee => elee.attribute_name != item.attribute_name).length > 0) {
                        return ele;
                    }
                }
            }).filter(iop => iop != undefined);

            if (ty.length > 0) {
                searchParams.set("variant", ty[0].variantid);

                setsel({
                    attr_value_id: ty[0].attributevalues_ids.split(",")[countindex]
                })

                setSearchParams(searchParams);
            } else {
                // searchParams.set("variant", ty[0].attributevalues_ids);
            }
            if (productDetailData[0].attributes.filter(yui => yui.variant_flag == 1).length > 1) {
                const uniqueValues = [...new Set(ty.flatMap(obj => obj.attributevalues_ids.split(',')[countindex]))];
                if (sel1.length > 0) {
                    setselectedattr(uniqueValues)
                }
            }

        }

    }, [sel1])



    return (
        <div className="product-variant">
            <div className="" >
                <div className="product-variant-heading">
                    <div className={`d-flex margin-bottom-xs`}>
                        <h5 className="filterTitle">Available {item.attribute_name}</h5>
                        <h5 className="selected-variant-text">{sel.attr_value_name}</h5>
                    </div>

                </div>
            </div>

            <div className="mb-2" >

                <div className="product-variant-list">
                    {
                        item.options.map(ele => {

                            var zu = productDetailData[0].variantList.filter(ele1 => ele1.attributevalues_ids.split(",").includes(ele.attr_value_id))

                            if (zu.length > 0) {
                                var tooltip_price = zu[0].selling_price
                            } else {
                                var tooltip_price = 0
                            }



                            return (
                                <div onClick={() => handleClick(ele.attr_value_name, ele.attr_value_id)} data-tooltip-id={`my-tooltip-${ele.attr_value_name}`} className={`${ele.hasOwnProperty("image") ? "product-varinat-item" : "product-varinat-item-text"} 
                                 ${ele.attr_value_id == sel.attr_value_id ? "product-varinat-item-active" : ""}






                                ${(sel1.length > 0 && selectedattr != undefined && sel1.filter(yui => yui.attribute_name != item.attribute_name).length > 0) && (selectedattr.includes(ele.attr_value_id) ? "" : "product-varinat-item-inactive")}
                                
                                `}>
                                    {ele.hasOwnProperty("image") ?
                                        <img referrerPolicy="no-referrer" src={ele.image}
                                            onError={e => {
                                                e.currentTarget.src = logo
                                            }} />
                                        :
                                        <h5 className="varient-list-data">{ele.attr_value_name}   </h5>}
                                    <ReactTooltip
                                        id={`my-tooltip-${ele.attr_value_name}`}
                                        place="top"
                                        content={<h5 className="custom-variant-tooltip">{ele.attr_value_name}<span className="border-right">{tooltip_price != 0 ? localStorage.getItem("currency") + tooltip_price : 'Out of Stock'}</span></h5>}
                                    />
                                </div>
                            )
                        })
                    }

                </div>

            </div>
            {/* {variant_error != '' && <div className='custom-error-text'>{variant_error + item.attribute_name}</div>}  */}

        </div>
    );
};

export default ProductVariants;
