import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as HiIcons from 'react-icons/hi';
import * as BsIcons from 'react-icons/bs';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { AllCategories } from '../common';
// import { useCookie } from '../../hooks';

const Sidebar = React.memo(() => {
    const dispatch = useDispatch()
    const langdata = useSelector(state => state.masterdata.langdata);
    const slectedkeys = useSelector(state => state.masterdata.slectedkeys);
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const userData = useSelector(state => state.masterdata.userData);
    const sidebar = useSelector(state => state.masterdata.sidebar);
    var categoryOpen = 'level1';
    // const { authStatus, userData, sidebar, setSidebar, categoryOpen } = useContext(StateContext);

    const isDesktoporLaptop = useMediaQuery({ query: `(max-width: 991px)` });

    useEffect(() => {
        if (sidebar == true) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }

    }, [sidebar])




    const showSidebar = () => {
        dispatch({ type: 'setsidebar', payload: !sidebar })
    };

    const SidebarNav = styled.div`
  width: ${isDesktoporLaptop ? "100%" : "400px"};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  box-shadow: ${isDesktoporLaptop ? "none" : "4px 0 10px 0 rgba(0,0,0,.4)"} ;
  top: 0;
  left: ${sidebar ? '0' : '-100%'};
  transition: 350ms;
  z-index: 10;
`;

    const SidebarWrap = styled.div`
  width: 100%;
  margin-top:15%;
  background:white;
  color:black;
  overflow-y:scroll;
  padding-bottom:50px;
`;

    // const { deleteCookie } = useCookie();


    const Handlelogout = () => {
        // deleteCookie()
        // showSidebar()
    }


    function SidebarsubCategory({ icon, type, title }) {

        return (
            <>
                {
                    type == "link" ?
                        <NavLink to="/my-account" className="text-decoration-none" onClick={showSidebar} >
                            <div className='category'>
                                <div className='category-leftside'>
                                    <div className='category-leftsideimg primaryColorcolor'>
                                        {icon}
                                    </div>
                                    <h5 className='category-leftsidetitle'>{title}</h5>
                                </div>
                            </div>
                        </NavLink>
                        :
                        <div className='category' onClick={title == 'Sign Out' && Handlelogout}>
                            <div className='category-leftside'>
                                <div className='category-leftsideimg primaryColorcolor'>
                                    {icon}
                                </div>
                                <h5 className='category-leftsidetitle'>{title}</h5>
                            </div>
                        </div>
                }
            </>
        )
    }

    return (
        <>
            <div style={{ display: "flex", cursor: "pointer" }} onClick={showSidebar} >
                <FaIcons.FaBars style={{ marginTop: isDesktoporLaptop ? "13px" : "3px", fontSize: isDesktoporLaptop ? "24px" : "" }} />
                {
                    !isDesktoporLaptop && <div className='ms-2' style={{ marginRight: "15px" }}>

                        {slectedkeys != '' && (langdata.length > 0) ? (
                            langdata
                                .filter(comp => comp.KEYS === "header_navlink_all")
                                .map(translatedData => (

                                    <span key={translatedData.id}>
                                        {translatedData[slectedkeys]}
                                    </span>

                                ))
                        ) : "All"

                        }



                    </div>
                }

            </div>
            <div className='sidebar-main' style={{ left: sidebar ? '0' : '-100%' }}>

                <div className='sidebar-top'>
                    <div className='sidebar-topleft'>
                        <div className='topleft-icon'>
                            <IoIcons.IoMdClose size="23" className='close-icon-sidebar' onClick={showSidebar} />
                        </div>
                        <h4 className='title'>
                            Hello {userData.name}
                        </h4>
                    </div>

                    {
                        !authStatus ?
                            <Link to="/login" onClick={showSidebar} className='sidebar-topright text-decoration-none'>
                                {
                                    slectedkeys != '' && (langdata.length > 0) ? (
                                        langdata
                                            .filter(comp => comp.KEYS === "navlink_ sign in")
                                            .map(translatedData => (

                                                <span key={translatedData.id}>
                                                    {translatedData[slectedkeys]}
                                                </span>

                                            ))
                                    ) : "Sign In"
                                }
                            </Link>
                            :
                            <div className='sidebar-topright-usericon'>

                                <BsIcons.BsFillPersonFill size="30" />

                            </div>

                    }


                </div>

                <SidebarWrap>
                    {
                        categoryOpen == "level1" ?

                            <>
                                <SubMenu customclass={"border-bottom padding-bottom"} title={"Shop By Category"} />

                                <AllCategories />
                                <SubMenu customclass={"border-top"} title={"Help & Settings"} />

                                <div className='main_category'>
                                    <SidebarsubCategory icon={<BsIcons.BsFillPersonFill size="30" />} type={"link"} title={"Your Account"} />
                                    <SidebarsubCategory icon={<HiIcons.HiPhoneIncoming size="30" />} type={"link"} title={"Contact Us"} />
                                    {authStatus && <SidebarsubCategory icon={<BsIcons.BsPower size="30" />} type={"function"} title={"Sign Out"} />}
                                </div>
                            </>
                            :
                            <AllCategories />

                        // SidebarData.map((item, index) => {
                        //     return (
                        //         (authStatus) ? <SubMenu item={item} key={index} /> : (!authStatus && item.title != 'Sign Out') && <SubMenu item={item} key={index} />
                        //     )
                        // })
                    }

                </SidebarWrap>
            </div>
        </>
    );
});

export default Sidebar;
