import React, { useState, useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import CartAction from '../../redux/actions/CartAction';
import { NavLink, useSearchParams } from 'react-router-dom';
const Varientdropdown = ({ variantList }) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { addToWishList } = CartAction(searchParams);
    const { addToCartProduct } = CartAction();
    const productvarientdata = useSelector(state => state.masterdata.productvarientdata);
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [hoveredVariant, setHoveredVariant] = useState(null); // State to track hovered variant
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const handleAddToCart = (Productid, Varientid) => {
        if (!authStatus) {
            const randomId = Math.floor(Math.random() * 100000000);
            if (localStorage.getItem("sessionId") === null) {
                localStorage.setItem('sessionId', randomId);
            }
        }
        const inputData = {
            "variantid": Varientid,
            "product_id": Productid,
            "quantity": 1,
            "type": 'product',
            ...(authStatus === false && { "session_id": localStorage.getItem("sessionId") })
        };
        dispatch(addToCartProduct(inputData));


    };
    const clickwishlist = (id, type) => {

        dispatch(addToWishList(id, type))
    }
    useEffect(() => {
        if (variantList && variantList.length > 0) {
            dispatch({
                type: 'setproductvarientdata',
                payload: variantList[0]
            });
        }
    }, [dispatch, variantList]);

    const handleToggle = (isOpen) => {
        setDropdownOpen(isOpen);
    };

    const selectedVariant = (id) => {
        const filteredVariant = variantList.find(ele => ele.variantid === id);
        if (filteredVariant) {
            dispatch({
                type: 'setproductvarientdata',
                payload: filteredVariant
            });
        }
    };

    const caretIcon = isDropdownOpen ? (
        <IoChevronUp className='varinat-caret-icon' />
    ) : (
        <IoChevronDown className='varinat-caret-icon' />
    );

    return (
        <div className='product-card-variant-dropdown'>
            <NavDropdown
                id="nav-dropdown-search"
                onToggle={handleToggle}
                className={`product-card-selected-variant ${isDropdownOpen && "selected"}`}
                title={
                    <>
                        {productvarientdata.variantname}
                        {caretIcon}
                    </>
                }
            >
                <div className='product-card-variant-dropdownlist'>
                    {variantList.map(variant => (
                        <NavDropdown.Item
                            key={variant.variantid}
                            className={`product-card-variant-listitem ${productvarientdata && productvarientdata.variantid === variant.variantid ? "selected_variant_active" : ""}`}
                            onClick={() => selectedVariant(variant.variantid)}
                            onMouseEnter={() => setHoveredVariant(variant.variantid)} // Set hovered variant on mouse enter
                            onMouseLeave={() => setHoveredVariant(null)} // Clear hovered variant on mouse leave
                        >
                            <div>
                                <div className='variant_title'>{variant.variantname}</div>
                                <div className='product-price Varient-details'>
                                    {
                                         variant.rstatus == 1 ?
                                         <div className=''>
                                         <div className="product-price product-price-revamp">
 
                                             <h5 className="Paragraph-16-M--Semibold varientsellingprice">{localStorage.getItem("currency") + variant.selling_price}</h5>
                                             {variant.mrp !== variant.selling_price && (
                                                 <h5 className="offer offer-revamp primaryColorcolor varient-mrp Paragraph-16-M--Regular">{localStorage.getItem("currency") + variant.mrp}</h5>
                                             )}
                                             {variant.mrp !== variant.selling_price && (
                                                 <h5 className="offer-label-revamp primaryColor Paragraph-12-XS--Semibold">
                                                     {Math.round(100 - ( variant.selling_price/variant.mrp ) * 100)}% OFF
                                                 </h5>
                                             )}
                                         </div>
 
                                     </div>:
                                     <div className='varientsellingprice'> Out Of Stock</div>
                                    }
                                    {hoveredVariant === variant.variantid && ( // Conditionally render "Add" div

                                        variant.rstatus == 1 ?
                                            <div className='varientaddtocart primaryColor' onClick={() => handleAddToCart(variant.productid, variant.variantid)}>Add</div> :
                                            (
                                                authStatus ?
                                                    <div className='varientaddtocart primaryColor' onClick={() => clickwishlist(variant.productid, 'product')}>Wishlist</div> :
                                                    <NavLink to={"/login"} className="text-decoration-none">
                                                        <div className='varientaddtocart primaryColor'>Wishlist</div>
                                                    </NavLink>


                                            )




                                    )}

                                </div>
                            </div>
                        </NavDropdown.Item>
                    ))}
                </div>
            </NavDropdown>
        </div>
    );
};

export default Varientdropdown;
