import React, { useState, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Col, Row } from 'react-bootstrap';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { Navigation, Pagination, Scrollbar, FreeMode, A11y } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../../images/bg_image.webp';
const ProductDetailSwiper = ({categoryData}) => {

    const [my_swiper, set_my_swiper] = useState({});

    const [index, setindex] = useState(0);

    const slidechange = (e) => {
        setindex(e.realIndex)
    }

    // const categoryData = useSelector(state => state.masterdata.categoryData);


    return (
        <Row>
            <Col lg={12}>
                <Swiper
                    effect="card"
                    loop={false}
                    onInit={(ev) => {
                        set_my_swiper(ev)
                    }}
                    breakpoints={{
                        200: {
                            slidesPerView: 4,
                        },
                        450: {
                            slidesPerView: 4,
                        },
                        760: {
                            slidesPerView: 6,
                        },
                        1000: {
                            slidesPerView: 7,
                        },
                        1200: {
                            slidesPerView: 9,
                        },
                    }}
                    autoplay={{
                        stopOnLastSlide: false,
                    }}
                    onSlideChange={(e) => slidechange(e)}
                    navigation={{
                        nextEl: ".next",
                        prevEl: ".previous"
                    }}
                    freeMode={true}
                    modules={[Navigation, Pagination, FreeMode, Scrollbar, A11y]}
                    className="mycategorySwiper"
                >
                    {
                        categoryData.map((ele2, index2) => {
                            // return ele.childCategory.map((ele1, index1) => {
                            // return ele1.childCategory.map((ele2, index2) => {
                            return (
                                <SwiperSlide
                                    className='categorySwiper-swiperslide'
                                    key={index2}>
                                    <NavLink to={`/category/${ele2.slug}`} className="text-decoration-none">
                                        <img 
                                        referrerPolicy="no-referrer"
                                        src={ele2.image != '' &&  ele2.image || ''} 
                                        onError={e => {
                                            e.currentTarget.src = logo
                                          }}
                                        />
                                        <h6 className='allcategory-title'>{ele2.label}</h6>
                                    </NavLink>
                                </SwiperSlide>


                            )
                        })
                    }

                </Swiper>

            </Col>


        </Row>
    );
};

export default ProductDetailSwiper;