import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import 'swiper/css/pagination';
import logo from '../../images/bg_image.webp';
import Cardproduct from "./Cardproduct";
import HeadingTitle from "./HeadingTitle";
const HomepageBrands = ({ position, Brandsdata }) => {
    const data = [
        {
            "image": "https://htmldemo.net/makali/makali/img/brand/b5.webp",
            "title": "modern brush",
            "price": 30,
            "status": "new",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/brand/b4.webp",
            "title": "modern brush",
            "price": 12,
            "status": "old",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/brand/b5.webp",
            "title": "modern brush",
            "price": 30,
            "status": "new",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/brand/b4.webp",
            "title": "modern brush",
            "price": 12,
            "status": "old",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/brand/b5.webp",
            "title": "modern brush",
            "price": 30,
            "status": "new",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/brand/b4.webp",
            "title": "modern brush",
            "price": 12,
            "status": "old",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/brand/b5.webp",
            "title": "modern brush",
            "price": 30,
            "status": "new",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/brand/b4.webp",
            "title": "modern brush",
            "price": 12,
            "status": "old",
            "discount": "30",

        }

    ]
    return (
        <>
            <div className="swiper-main">

                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={20}
                    effect="cards"
                    // loop={true}
                    className=""
                    navigation={{
                        nextEl: `.nextbtn${position}`,
                        prevEl: `.previousbtn${position}`,
                    }}
                    breakpoints={{
                        200: {
                            slidesPerView: 2.5,
                        },
                        400: {
                            slidesPerView: 2.5,
                        },
                        700: {
                            slidesPerView: 2,
                        },
                        750: {
                            slidesPerView: 3,
                        },
                        1000: {
                            slidesPerView: 5,
                        },
                        1200: {
                            slidesPerView: 5,
                        },
                        1400: {
                            slidesPerView: 5,
                        },
                        1600: {
                            slidesPerView: 5,
                        },
                        1800: {
                            slidesPerView: 5,
                        },
                    }}
                // className={`mycategorySwiper${1}`}

                >
                    <div>

                        return (
                        {
                            Brandsdata.map(ele => {
                                return (
                                    <SwiperSlide >
                                        <img className="homepagebrands-images" src={localStorage.getItem("cdn_domain") + ele.image} onError={e => {
                                            e.currentTarget.src = logo
                                        }}></img>
                                    </SwiperSlide>
                                )
                            })
                        }
                        )
                    </div>

                </Swiper>
                <div >
                    <div className={`previousbtn primaryColorHover secondaryColor previousbtn${position} `}><BiSolidChevronLeft /></div>
                    <div className={`nextbtn primaryColorHover secondaryColor nextbtn${position} `}><BiSolidChevronRight /></div>
                </div>
            </div>
        </>
    )
}
export default HomepageBrands;