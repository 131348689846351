import React, { useState, useEffect } from "react";
import { BsDash, BsPlus } from 'react-icons/bs';
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CartAction from "../../redux/actions/CartAction";

const Bundles = ({ bundledata, price, stock_status }) => {
   
    const dispatch = useDispatch();
    const { addToCartProduct } = CartAction();
    const authStatus = useSelector(state => state.masterdata.authStatus);

    // Initialize state for selected products with default quantity 1
    const [selectedProducts, setSelectedProducts] = useState(
        bundledata.reduce((acc, ele) => {
            acc[ele.productDetail.productid] = { quantity: 1 };
            return acc;
        }, {})
    );
    // State to keep track of the total amount
    const [amount, setAmount] = useState(price);
    // Toggle product selection by clicking on the entire product container
    const toggleProductSelection = (productId) => {
        setSelectedProducts(prevSelected => {
            if (prevSelected[productId]) {
                // Remove the product if it is already selected
                const { [productId]: removed, ...rest } = prevSelected;
                return rest;
            } else {
                // Add the product with default quantity 1
                return {
                    ...prevSelected,
                    [productId]: { quantity: 1 }
                };
            }
        });
    };

    // Update the total amount whenever the selected products change
    useEffect(() => {
        const totalAmount = Object.entries(selectedProducts).reduce((sum, [productId, { quantity }]) => {
            const product = bundledata.find(ele => ele.productDetail.productid === productId);
            return sum + (product.productDetail.selling_price * quantity);
        }, 0);
        setAmount(totalAmount);
    }, [selectedProducts, bundledata]);

    useEffect(() => {
        dispatch({
            type: "Setbundelamount",
            payload: amount
        });
    }, [amount]);

    // Function to handle quantity change
    const handleQuantityChange = (productId, increment) => {
        setSelectedProducts(prevSelected => {
            if (!prevSelected[productId]) return prevSelected;

            const newQuantity = prevSelected[productId].quantity + increment;

            // Deselect product if the quantity would drop below 1
            if (newQuantity < 1) {
                const { [productId]: removed, ...rest } = prevSelected;
                return rest;
            }

            return {
                ...prevSelected,
                [productId]: {
                    ...prevSelected[productId],
                    quantity: newQuantity
                }
            };
        });
    };

    // Convert selected products to an array of objects with productId and quantity
    const getSelectedProductsArray = () => {
        return Object.entries(selectedProducts).map(([productId, { quantity }]) => ({
            productId,
            quantity
        }));
    };

    const selectedProductsArray = getSelectedProductsArray();

    // Function to handle adding selected products to the cart
    const Bundleaddtocart = () => {
        if (!authStatus) {
            const randomId = Math.floor(Math.random() * 100000000);
            if (!localStorage.getItem("sessionId")) {
                localStorage.setItem('sessionId', randomId);
            }
        }

        selectedProductsArray.forEach(ele => {
            const input_data = {
                product_id: ele.productId,
                quantity: ele.quantity,
                type: "product",
                ...(authStatus === false && { session_id: localStorage.getItem("sessionId") })
            };
            dispatch(addToCartProduct(input_data));
        });
    };

    return (
        <div className="bundle-top">
            <div>You have selected {selectedProductsArray.length} items</div>
            <div>No discount is applied on the selected products.</div>
            {bundledata.map(ele => {
                const { productDetail } = ele;
                const isChecked = !!selectedProducts[productDetail.productid];
                const quantity = isChecked ? selectedProducts[productDetail.productid].quantity : 1;

                return (
                    <div
                        className={`bunder-outer ${isChecked ? "Product-selected" : ""}`}
                        key={productDetail.productid}
                        onClick={() => toggleProductSelection(productDetail.productid)}
                    >
                        <div className="bundle-products">
                            <div className="Addon-image-div">
                                <img
                                    className='image-addon'
                                    src={productDetail.product_images[0] || '/path/to/placeholder-image.jpg'}
                                    alt={productDetail.product_title}
                                />
                            </div>
                            <div>
                                <h6>{productDetail.product_title}</h6>
                                <h6>{localStorage.getItem("currency")}{productDetail.selling_price}</h6>
                                {isChecked && (
                                    <Col lg={3} className='cart-quantity-div'>
                                        <div className='quantityContainer1'>
                                            <div
                                                className='plusButton1'
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent toggling selection on quantity button click
                                                    handleQuantityChange(productDetail.productid, -1);
                                                }}
                                            >
                                                <BsDash size="22" className='plusButton1-btn-icon' />
                                            </div>

                                            <div className="quantity1">
                                                {quantity}
                                            </div>

                                            <div
                                                className="plusButton1"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent toggling selection on quantity button click
                                                    handleQuantityChange(productDetail.productid, 1);
                                                }}
                                            >
                                                <BsPlus className='plusButton1-btn-icon' size="22" />
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className='product-detail-buttongroup product-detal-cart_btn'>
                {stock_status === "1" ? (
                    <div
                        style={{ lineHeight: "15px" }}
                        className='product-detail-button cart-btn primaryColor'
                        onClick={Bundleaddtocart}
                    >
                        Add to Cart
                    </div>
                ) : (
                    <div
                        style={{ lineHeight: "15px" }}
                        className='product-detail-button cart-btn primaryColor'
                    >
                        Out Of Stock
                    </div>
                )}
            </div>
        </div>
    );
};

export default Bundles;
