import React from 'react';
import { CheckBox, FilterCategories } from './index';
// import Price from './Price';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Filters = ({ filtersdata }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const productcatFiltersData = useSelector(state => state.catlogdetail.productcatFiltersData);
    const filtertotaldata =useSelector(state=>state.catlogdetail.filtertotaldata);
    const filteritems =useSelector(state=>state.catlogdetail.filteritems);
    const clearFilterArray = () => {

        filtersdata.checkbox.map(ele1 => {
            searchParams.delete(ele1.title);
            setSearchParams(searchParams);
        })

    };
    return (
        <div className='mainFilter'>
            <div className='custom-border-bottom'>

         
            <div className='main-filtw-hedER '>
                <div className='filter-products-list'>
                <div className='filters-heading'>Filters </div>
               
                </div>
             
                <div className='filters-clear-all' onClick={clearFilterArray}>Clear All</div>
            </div>
            {/* <div className='filters-products'>{productcatFiltersData.hasOwnProperty("display_items") && productcatFiltersData.display_items.products.length} of {filtertotaldata.filters} Products</div> */}
            </div>
            {
                filtersdata.categories.map((ele,index) => {
                    return (
                        <FilterCategories item={ele} key={index} />
                    )
                })
            }
            {/* {<Price />} */}
            {
                filtersdata.checkbox.map((ele, index) => {
                    return (
                        <CheckBox item={ele} type={index > 1 ? false : true} key={index} />
                    )
                })
            }

        </div>

    );

};

export default Filters;