import React, { useState, useContext, useEffect } from 'react';

import { InputBox, TextArea } from '../common';
import {
    Row,
    Col,
} from "react-bootstrap";

import validate from "../../validations/FormValidationRules";

import { useMediaQuery } from "react-responsive";

import { useAddress } from '../../hooks';
import { useSelector,useDispatch } from 'react-redux';

const BillingAddressForm = ({ type }) => {

    const dispatch = useDispatch()

    const [isSubmitting, setIsSubmitting] = useState(false);

    const addressList = useSelector(state => state.addressdata.addressList);
    const billingformValues = useSelector(state => state.addressdata.billingformValues);     
    const billingerrors = useSelector(state => state.addressdata.billingerrors);     
    const formValues = useSelector(state => state.addressdata.formValues);     
    const addressOpen = useSelector(state => state.addressdata.addressOpen);     
    const checkedbilling = useSelector(state => state.addressdata.checkedbilling);     
    const addressData = useSelector(state => state.addressdata.addressData);     

    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });


    const handleChange = (event) => {


        event.persist();

        dispatch({
            type: 'setbillingFormValues', payload: { ...billingformValues, [event.target.name]: event.target.value }
        })

    };


    const handleCheckbox = (e) => {
        if (type == 3 || addressOpen == 5) {
            dispatch({
                type: 'setcheckedbilling', payload: e.target.checked
            })
        }
    }

    useEffect(() => {
        if (type == 3) {
            if (!checkedbilling) {
                var billing_add = addressList.filter(ele => ele.addresstype == 'Billing')[0]
            } else {
                if(isDesktoporMobile){
                    var billing_add = addressList.filter(ele => ele.default == '1')[0]
                }else{
                    var billing_add = formValues
                }
            }
            dispatch({
                type: 'setbillingFormValues', payload:{
                    billingname: billing_add.name,
                    billingphone: billing_add.phone,
                    billingaddress: billing_add.address,
                    billingpincode: billing_add.pincode,
                    billingstate: billing_add.state,
                    addressId: "0",
                    addresstype: "Billing"
                }
            })
        }
    }, [checkedbilling])


    useEffect(() => {
        if(isDesktoporMobile){
            if ((Object.keys(billingerrors).length === 0) && isSubmitting) {
                dispatch({
                    type: 'setAddressopen', payload:0
                })
            }
        }
       
    }, [billingerrors]);


    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        dispatch({
            type: 'setbillingErrors', payload:validate(billingformValues)
        })
        setIsSubmitting(true);
    };

    return (
        <div className={type != 3 ? 'checkout-address-form' : 'checkout-paymentmethod-address-form'}>
            <div className='checkout-address-form-title'>
                {(type != 3) && ((addressData.length > 0) ? "Edit Address" : "Add new Address")}
            </div>
            <div className='address-form'>
                <Row>
                    <Col lg={12}>
                        {
                            (!isDesktoporMobile && type == 3 || addressOpen == 5) &&
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="exampleCheck1"
                                    checked={checkedbilling}
                                    onChange={(e) => handleCheckbox(e)}
                                />
                                <label className="form-check-label" htmlFor="exampleCheck1">
                                    My Shipping Address and Billing Address are same
                                </label>
                            </div>
                        }

                    </Col>

                    <div className='mt-4'></div>

                    <Col lg={6} >
                        <InputBox label={"Name"} id={"billingname"} handleChange={handleChange} errors={billingerrors} value={billingformValues.billingname} />
                    </Col>
                    <Col lg={6}>
                        <InputBox label={"Phone"} id={"billingphone"} handleChange={handleChange} errors={billingerrors} value={billingformValues.billingphone} />
                    </Col>
                    <Col lg={12}>
                        <TextArea label={"Address"} id={"billingaddress"} handleChange={handleChange} errors={billingerrors} value={billingformValues.billingaddress} />
                    </Col>

                    <Col lg={6}>
                        <InputBox label={"Postal Code"} id={"billingpincode"} handleChange={handleChange} errors={billingerrors} value={billingformValues.billingpincode} />
                    </Col>
                    <Col lg={6}>
                        <InputBox label={"State"} id={"billingstate"} handleChange={handleChange} errors={billingerrors} value={billingformValues.billingstate} />
                    </Col>


                </Row>
                {
                    isDesktoporMobile &&
                    <Row>
                        <div className='buttons-product-detaildiv' onClick={handleSubmit}>
                            <div className='product-detail-buttongroup'>
                                <div className='product-detail-button text-decoration-none primaryColor'>
                                    Update Here
                                </div>
                            </div>
                        </div>

                    </Row>

                }
            </div>
        </div>
    );
};

export default BillingAddressForm;