import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import 'swiper/css/pagination';
import Cardproduct from "./Cardproduct";
import HeadingTitle from "./HeadingTitle";
import { Col, Container, Row } from "react-bootstrap";
const BackgroundimagCarousel = ({ position, backgrounddata }) => {
    const data = [
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/12.webp",
            "title": "modern brush",
            "price": 30,
            "status": "new",
            "discount": "30",
            "rating": 2

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/4.webp",
            "title": "modern brush",
            "price": 12,
            "status": "old",
            "discount": "30",
            "rating": 4

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/6.webp",
            "title": "modern brush",
            "price": 300,
            "status": "new",
            "discount": "30",
            "rating": 2

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/1.webp",
            "title": "modern brush",
            "price": 300000,
            "status": "new",
            "discount": "30",
            "rating": 2

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/5.webp",
            "title": "modern brush",
            "price": 344440,
            "status": "new",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/12.webp",
            "title": "modern brush",
            "price": 30,
            "status": "new",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/4.webp",
            "title": "modern brush",
            "price": 12,
            "status": "old",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/6.webp",
            "title": "modern brush",
            "price": 300,
            "status": "new",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/1.webp",
            "title": "modern brush",
            "price": 300000,
            "status": "new",
            "discount": "30",

        },
        {
            "image": "https://htmldemo.net/makali/makali/img/products/cosmetic/5.webp",
            "title": "modern brush",
            "price": 344440,
            "status": "new",
            "discount": "30",

        }
    ]
    return (
        <div style={{
            backgroundImage: `url(${backgrounddata.carosal_image})`,
            backgroundSize: 'cover',      // Ensures the background covers the whole container
        }}>
            {/* <HeadingTitle/> */}
            <Container>
                {
                    backgrounddata &&
                    backgrounddata.item &&
                    backgrounddata.item.length> 0 &&
                    <Row >
                        <Col lg={6} sm={6} xs={12}></Col>
                        <Col lg={6} sm={6} xs={12}>
                            <HeadingTitle className={"start"} className1={"backgroundimage-heading"} titleclass={"headtitle"} discriptionclass={"headdiscription"} HeadingTitle={backgrounddata.carosal_heading} content={backgrounddata.description} />
                            <div className="swiper-main">

                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                    spaceBetween={20}
                                    effect="cards"
                                    // loop={true}
                                    className=""
                                    navigation={{
                                        nextEl: `.nextbtn${position}`,
                                        prevEl: `.previousbtn${position}`,
                                    }}
                                    breakpoints={{
                                        200: {
                                            slidesPerView: 1,
                                        },
                                        400: {
                                            slidesPerView: 1,
                                        },
                                        700: {
                                            slidesPerView: 1,
                                        },
                                        750: {
                                            slidesPerView: 1,
                                        },
                                        1000: {
                                            slidesPerView: 2,
                                        },
                                        1200: {
                                            slidesPerView: 2,
                                        },
                                        1400: {
                                            slidesPerView: 2,
                                        },
                                        1600: {
                                            slidesPerView: 2,
                                        },
                                        1800: {
                                            slidesPerView: 2,
                                        },
                                    }}
                                // className={`mycategorySwiper${1}`}

                                >
                                    <div>
                                        {
                                            backgrounddata &&
                                            backgrounddata.item &&
                                            backgrounddata.item.length > 0 &&
                                            backgrounddata.items.map(ele => {
                                                return (
                                                    <SwiperSlide >
                                                        <Row className="countdown">
                                                            <Col className="count-main" lg={3} md={3} sm={3} xs={3}>
                                                                <div class="count">
                                                                    <p className="count-detailscolor primaryColorcolor">00</p>
                                                                    <span className="count-status-title">Days</span>
                                                                </div>
                                                            </Col>

                                                            <Col className="count-main" lg={3} md={3} sm={3} xs={3}>
                                                                <div class="count">
                                                                    <p className="count-detailscolor primaryColorcolor">00</p>
                                                                    <span className="count-status-title">Hours</span>
                                                                </div>
                                                            </Col>
                                                            <Col className="count-main" lg={3} md={3} sm={3} xs={3}>
                                                                <div class="count">
                                                                    <p className="count-detailscolor primaryColorcolor">00</p>
                                                                    <span className="count-status-title">Mins</span>
                                                                </div>
                                                            </Col>
                                                            <Col className="count-main" lg={3} md={3} sm={3} xs={3}>
                                                                <div class="count">
                                                                    <p className="count-detailscolor primaryColorcolor">00</p>
                                                                    <span className="count-status-title">Secs</span>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Cardproduct image={ele.product_images[0]} title={ele.product_title} className1={"backgroundimage-content"} status={ele.type} price={ele.selling_price} rating={ele.rating} discount={ele.discount_percentage} />
                                                    </SwiperSlide>
                                                )
                                            })

                                        }






                                    </div>

                                </Swiper>

                                <div >
                                    <div className={`previousbtn primaryColorHover secondaryColor previousbtn${position} `}><BiSolidChevronLeft /></div>
                                    <div className={`nextbtn primaryColorHover secondaryColor nextbtn${position} `}><BiSolidChevronRight /></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }
            </Container>
        </div>
    )
}
export default BackgroundimagCarousel;