import React, { useState } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import androdimg from "../../images/images.png"
// import androdimg from '../../images/androidapp.png'
import iosimg from '../../images/ios-store.png'
import { SiMinutemailer } from "react-icons/si";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import logo from '../../images/bg_image.webp';
import { useSelector } from "react-redux";
import Newsletterform from "./Newsletterform";
// import appstore from '../../images/appstore.webp';
// import iosstore from '../../images/ios-store.webp';
import { BsWhatsapp, BsLinkedin, BsInstagram, BsFacebook } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";


const Footer = () => {
  const footerdata = useSelector(state => state.masterdata.footerdata);
  const navdata = useSelector(state => state.masterdata.navdata);
  const mobileapplink = navdata.filter(ele => ele.list_key == "mobile_app")
  const andriod = mobileapplink.filter(ele => ele.name == "Android")
  const IOS = mobileapplink.filter(ele => ele.name == "IOS")
  const [show, setShow] = useState(7);
  const [buttonShow, setButtonShow] = useState(true);
  const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  const handelViewMore = () => {
    setShow(-1);
    setButtonShow(false);
  };

  const handelViewLess = () => {
    setShow(7);
    setButtonShow(true);
  };

  return (
    <>
      <div>
      
      </div>
      <div className="footerSecondContainer">
        <Container

          className="maxWidthContainerFluid paddingContainerFluid"
        >
          <Row >
            <Col lg={3} md={3} sm={12} xs={12}>
              <Row>
                <Col lg={12} className="footer-logo-main">
                  <img
                    referrerPolicy="no-referrer"
                    src={localStorage.getItem('footer_logo')}
                    onError={e => {
                      e.currentTarget.src = logo
                    }}
                    className="footerLogoimage"
                  />
                  {/* <h3 className="footer_descip">{localStorage.getItem("address")}</h3> */}
                  {/* <p className="footer_descip">
                    <div dangerouslySetInnerHTML={{ __html: localStorage.getItem("domain_descrption") }} />


                  </p> */}
                </Col>
              </Row>
              {
              (andriod.length > 0 && andriod[0]?.url !== "") || (IOS.length > 0 && IOS[0]?.url !== "") ? (
                <Col lg={6} md={6} sm={6} xs={6}>
             
                  <Row className="mobilapp-rightside" lg={12}>
                    {andriod.length > 0 && andriod[0]?.url !== "" && (
                      <Col lg={12} md={12} sm={12}>
                        <NavLink to={andriod[0].url} target="_blank">
                          <img className="andriodimg" src={androdimg} alt="Android Download" />
                        </NavLink>
                      </Col>
                    )}
                    {IOS.length > 0 && IOS[0]?.url !== "" && (
                      <Col lg={12} md={12} sm={12}>
                        <NavLink to={IOS[0].url} target="_blank">
                          <img className="iosimag-div" src={iosimg} alt="iOS Download" />
                        </NavLink>
                      </Col>
                    )}
                  </Row>
                </Col>
              ) : null
            }
              {/* <Row style={{ paddingTop: "8px" }}>
                <Col lg={12}>

                  <div >
                    <h1 className="Connectwithus">Connect with us</h1>
                    <div className="sosalmidiaimg">
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="facebook"
                      >
                        <BsFacebook color="#fff" size="20" />

                      </a>

                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="instagram"
                      >
                        <BsInstagram color="#fff" size="20" />
                      </a>
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="whatsapp"
                      >
                        <BsWhatsapp color="#fff" size="20" />
                      </a>
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="linkedIn"
                      >
                        <BsLinkedin color="#fff" size="20" />
                      </a>
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="twitter"
                      >
                        <AiOutlineYoutube color="#fff" size="25" />
                      </a>
                    </div>
                  </div>
                </Col>
              </Row> */}
            </Col>
            {/* <Col lg={3} md={3} sm={12} xs={12}>
              <div className="wearealways">CONSUMER POLICY</div>
              {
                footerdata.length > 0 &&
                footerdata.map((ele, index) => {
                  return (

                    <div className="footer_descip">
                      <NavLink to={`/policies/${ele.list_key}`} className={"text-decoration-none"} >
                        {ele.title}
                      </NavLink>
                    </div>


                  )
                })
              }
            </Col> */}
            {/* <Col lg={3} md={3} sm={12} xs={12}>

              <Row>
                <Col lg={12}>
                  <h1 className="wearealways">We're always here to help you</h1>
                  <p className="Reachout">Reach out to us through any of these support channels</p>
                  <div className="midiacont">
                    <div dangerouslySetInnerHTML={{ __html: localStorage.getItem("domain_address") }} />
                    <div style={{ display: "flex" }}>

                      <p className="massages">
                        whatsapp : {" "}
                      </p>
                      <p className="massages" style={{ marginLeft: "7px" }}>
                        <NavLink to={`https://wa.me/${localStorage.getItem("domain_whatsapp")}`} className={"text-decoration-none"} target="_blank">
                          {localStorage.getItem("domain_whatsapp")}
                        </NavLink>
                      </p>
                    </div>

                    <div style={{ display: "flex" }}>
                      <p className="massages">
                        email : {" "}
                      </p>
                      <p className="massages" style={{ marginLeft: "7px" }}>
                        {
                          <NavLink to={`mailto:${localStorage.getItem("domain_email")}`} className={"text-decoration-none"} target="_blank">

                            {localStorage.getItem("domain_email")}
                          </NavLink>
                        }

                      </p>
                    </div>

                  </div>
                </Col>
              </Row>
            </Col> */}
             <Col lg={3} md={3} sm={12} xs={12}>
          {/* <Newsletterform/> */}
          </Col>

            <Col lg={3} md={3} sm={12} xs={12}>

              <h1 className="wearealways">CONTACT US</h1>
              <div className="footer-contact">
                <div className="footer-contact-icon primaryColorcolor phone-info-icon">
                  <FaMapMarkerAlt />
                </div>
                <div className="footer-contact-desc phone-info-desc">
                  <h6>Visit Us Daily</h6>
                  {

                    <div className="footer-details-content" dangerouslySetInnerHTML={{ __html: localStorage.getItem("domain_address") }} />
                  }

                </div>
              </div>
              <div className="footer-contact">
                <div className="footer-contact-icon primaryColorcolor phone-info-icon">
                  <NavLink to={`https://wa.me/${localStorage.getItem("domain_whatsapp")}`} className={"text-decoration-none"} target="_blank">
                    <FaPhoneAlt />
                  </NavLink>
                </div>
                <div className="footer-contact-desc phone-info-desc">
                  <h6><NavLink to={`https://wa.me/${localStorage.getItem("domain_whatsapp")}`} className={"text-decoration-none"} target="_blank">Have Any Questions?</NavLink></h6>
                  <NavLink to={`https://wa.me/${localStorage.getItem("domain_whatsapp")}`} className={"text-decoration-none footer-details-content"} target="_blank">
                    91+ {localStorage.getItem("domain_whatsapp")}
                  </NavLink>
                </div>
              </div>
              <div className="footer-contact">
                <div className="footer-contact-icon primaryColorcolor phone-info-icon">
                  <NavLink to={`mailto:${localStorage.getItem("domain_email")}`} target="_blank" className={"text-decoration-none"}>
                    <SiMinutemailer />
                  </NavLink>
                </div>

                <div className="footer-contact-desc  phone-info-desc">
                  <h6><NavLink to={`mailto:${localStorage.getItem("domain_email")}`} target='_blank' className={"text-decoration-none"}>Mail Us</NavLink></h6>
                  <NavLink to={`mailto:${localStorage.getItem("domain_email")} `} target='_blank' className={"text-decoration-none"}>
                    {

                      <p>{localStorage.getItem("domain_email")}</p>
                    }
                  </NavLink>
                </div>
              </div>

            </Col>
          



            <Col lg={3} md={3} sm={12} xs={12}>
          <Newsletterform/>
          </Col>
          </Row>
          {


            !isMobile ?
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="footer-types">
                  <div className="middle_paragraph">
                    {
                      footerdata.length > 0 &&
                      footerdata.map((ele, index) => {
                        return (
                          <NavLink to={`/policies/${ele.list_key}`} className="dddddd">
                            <h5 className="footer-line">
                              {index !== 0 && " | "}
                            </h5>
                            {" "}
                            {ele.title}{" "}
                          </NavLink>
                        )
                      })
                    }

                  </div>
                </Col>   </Row>
              :
              <Row className="fotter-contact">

                {
                  footerdata.length > 0 &&
                  footerdata.map((ele, index) => {
                    return (

                      <Col lg={6} md={4} sm={6} xs={6} className="footer-text-contant">
                        <NavLink to={`/policies/${ele.list_key}`} className={"text-decoration-none"} >
                          {ele.title}
                        </NavLink>
                      </Col>


                    )
                  })
                }

              </Row>
          }
          <Row style={{ paddingTop: "20px" }}>

            <Col lg={12}>
              <div className="footer_component">
                <p className="ourshop_com">{localStorage.getItem("website_copyright")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </>
  );
};

export default Footer;
