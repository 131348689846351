import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import MasterAction from "./redux/actions/MasterAction";
import CartAction from "./redux/actions/CartAction";
import AddressAction from "./redux/actions/AddressAction";
import AppRouter from "./routers/AppRouter";
import Cookies from "universal-cookie";

import { useCookie } from "./hooks";
import ReactGA from "react-ga4";
import * as XLSX from 'xlsx';
import { Helmet } from "react-helmet";
const App = () => {
  const dispatch = useDispatch();
  const { getCookie } = useCookie();
  const { getMasterData, getcategorylistData, categorylistdatalimit, getHomepageData, getVendorToken, getStaticPageListdatadata, getNavigationlistdata, globaljsondata } = MasterAction();
  const { getAddressList } = AddressAction();
  const { getCartList } = CartAction();
  const cookies = new Cookies();
  const vendor_token = useSelector(state => state.masterdata.vendor_token);
  const masterData = useSelector(state => state.masterdata.masterData);
  const categorylimitdata = useSelector(state => state.masterdata.categorylimitdata);
  const NavigationStatus = useSelector(state => state.masterdata.NavigationStatus);
  console.log("masterData", masterData)
  console.log("NavigationStatus", NavigationStatus)
  if (localStorage.getItem("ga_id") != null) {
    ReactGA.initialize(localStorage.getItem("ga_id"));
  }

  useEffect(() => {
    if (vendor_token !== '') {
      getCookie();
      dispatch(getMasterData());
    }
  }, [vendor_token]);

  useEffect(() => {
    if (Object.keys(masterData).length > 0) {
      dispatch(categorylistdatalimit());
      dispatch(globaljsondata());
    }
  }, [masterData]);
  useEffect(() => {

      if (Object.keys(categorylimitdata).length > 0 && NavigationStatus.length > 0) {
        dispatch(getcategorylistData());
        dispatch(getHomepageData());
        dispatch(getNavigationlistdata());
        dispatch(getStaticPageListdatadata());
        dispatch(getCartList());
        if (cookies.get("jwt_token") !== undefined) {
          dispatch(getAddressList());
        }

      }

     
  }, [categorylimitdata,NavigationStatus]);
  useEffect(() => {
    const properties = ['header_bg', 'primary_color', 'header_text_color', 'nav_bg', 'nav_text_color', 'app_aspect_ratio'];
    properties.forEach(prop => {
      document.body.style.setProperty(`--${prop.replace(/_/g, '-')}`, localStorage.getItem(prop));
    });
    if (localStorage.getItem("qwerty") === null) {
      dispatch(getVendorToken());
    } else {
      dispatch({ type: 'setvendor_token', payload: localStorage.getItem("qwerty") });
    }
  }, []);

  // Uncomment and update the following `useEffect` if you want to fetch and process the Excel file
  // useEffect(() => {
  //   const fetchExcelFile = async () => {
  //     try {
  //       const response = await fetch('./images/translanguage.xlsx');
  //       const blob = await response.blob();

  //       const reader = new FileReader();

  //       reader.onload = (e) => {
  //         const data = new Uint8Array(e.target.result);
  //         const workbook = XLSX.read(data, { type: 'array' });

  //         const jsonData = convertToJSON(workbook);
  //         dispatch({
  //           type: 'setlangdata',
  //           payload: jsonData.Web_Translate
  //         });
  //       };
  //       reader.readAsArrayBuffer(blob);
  //     } catch (error) {
  //     }
  //   };

  //   fetchExcelFile();

  //   const convertToJSON = (workbook) => {
  //     const result = {};

  //     workbook.SheetNames.forEach((sheetName) => {
  //       const sheet = workbook.Sheets[sheetName];
  //       result[sheetName] = XLSX.utils.sheet_to_json(sheet);
  //     });

  //     return result;
  //   };
  // }, []);

  return (
    <>
      <Helmet>
        <title>{localStorage.getItem("title") - localStorage.getItem("domain_title")}</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
      </Helmet>
      {
        localStorage.getItem("qwerty") !== null && <AppRouter />
      }

    </>
  );
};
export default App;
