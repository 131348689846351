import React, { useState, useContext, useEffect } from 'react'
import { InputBox, OtpForm } from '../../components/common';
import validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation'
import { useOrders } from '../../hooks';
import { StateContext } from '../context/ManageStates';
// import AuthAction from '../redux/actions/AuthAction';
import MasterAction from '../../redux/actions/MasterAction';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../components/firebase/Setup';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
const ForgotPassword1 = () => {
    const { getOtppassword } = MasterAction();
    const [currentStep, setcurrentStep] = useState(0);
    const [user, setuser] = useState(null);
    const verification_status = useSelector(state => state.orderdetail.verification_status);
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState(
        // {
        //     phone: "",
        // }
        localStorage.getItem("verification_method") == 1 ? {

            "phone": "",
        }
            :
            {

                "email": "",
            }
    )


    const { values, errors, handleChange, setErrors, handleSubmit, } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );

    async function handleNext() {

        if (localStorage.getItem("verification_method") == 1) {

            //phone firebase
            dispatch({ type: 'setpwd_Email', payload: values.phone })

            const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {});
            const phoneNumberWithCode = `+91${values.phone}`;
            const confirmation = await signInWithPhoneNumber(auth, phoneNumberWithCode, recaptchaVerifier);
            dispatch({ type: 'setStep', payload: 2 })
            dispatch({ type: 'setuser', payload: confirmation })
            // setuser(confirmation)
            // dispatch(getOtppassword(input_data, errors))
        } else {
            //email
            dispatch({ type: 'setpwd_Email', payload: values.email })
            dispatch(getOtppassword(values, errors))
        }
    }


    return (
        <div className='ForgotPassword-mian-login'>
            <div className='ForgotPassword-main'>
                <div className='ForgotPassword-title'>Password assistance</div>
                <div className='ForgotPassword-status'>Enter the email address or mobile phone number associated with your account.</div>

                {
                    localStorage.getItem("verification_method") == 1 ?

                        <InputBox label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} />
                        :
                        <InputBox label={"Email"} id={"email"} handleChange={handleChange} errors={errors} />
                }
                {/* <InputBox label={"Email or mobile phone number"} id={"email"} handleChange={handleChange} errors={errors} /> */}
               {
                localStorage.getItem("verification_method") == 1 &&
                <div id="recaptcha"></div>
               }
              
                <div className='product-detail-buttongroup login-register-btn'>
                    <div className={`product-detail-button primaryColor secondaryColorHover`} onClick={handleSubmit}>
                        Continue
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ForgotPassword1;