import { motion, animate } from 'framer-motion';
import { useEffect, useRef } from 'react';

function Progressbar({ value, star }) {
  const progressTextRef = useRef(null);

  useEffect(() => {
    // Ensure the ref is not null before accessing textContent
    if (progressTextRef.current) {
      animate(0, value, {
        duration: 2,
        onUpdate: (cv) => {
          // Double-check the ref is still available during updates
          if (progressTextRef.current) {
            progressTextRef.current.textContent = cv.toFixed(0);
          }
        },
      });
    }
  }, [value]);

  return (
    <div className="progressbar-container">
      <div className="progressbar-startext">
        <div>
          {star} <span>star</span>
        </div>
      </div>
      <div className="progressbar">
        <motion.div
          className="primaryColor"
          animate={{
            width: `${value}%`,
          }}
          transition={{
            duration: 2,
          }}
        />
      </div>
      <div className="progressbar-text-container">
        <div ref={progressTextRef}>0</div> <div>%</div>
      </div>
    </div>
  );
}

export default Progressbar;
