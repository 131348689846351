const initialState = {
  productDetailData: [],
  prodDetailBottomSection: [],
  addon: [],
  sel2: [],
  quantity: 1,
  productdata:[],
  productmrp:"",
  productSellingprice:"",
  Productid:{},
  bundlesdata:[],
  bundleapicall:"",
  Bundelamount:"",
  bundle_apislug:""
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'Setproductmrp':
      return{
        ...state,
        productmrp:action.payload
      }
    case 'Setbundelamount':
      return{
        ...state,
        Bundelamount:action.payload
      }
    case 'setbundle_apislug':
      return{
        ...state,
        bundle_apislug:action.payload
      }
    case 'setbundleapicall':
      return{
        ...state,
        bundleapicall:action.payload
      }
    case 'Setbundledata':
      return{
        ...state,
        bundlesdata:action.payload
      }
    case 'Setproductdata':
      return{
        ...state,
        productdata:action.payload
      }
    case 'setproductSellingprice':
      return{
        ...state,
        productSellingprice:action.payload
      }
    case 'setProductid':
      return{
        ...state,
        Productid:action.payload
      }
  
    case 'subcategory_data':
      return {
        ...state,
        productcatData: action.payload
      };
    case 'setproductDetailData':
      return {
        ...state,
        productDetailData: action.payload
      };
    case 'setaddon':
      return {
        ...state,
        addon: action.payload
      };
    case 'setquantity':
      return {
        ...state,
        quantity: action.payload
      };
    case 'setprodDetailBottomSection':
      return {
        ...state,
        prodDetailBottomSection: action.payload
      };
    case 'setsel2':
      return {
        ...state,
        sel2: action.payload
      };
    default:
      return state;
  }
};
