const initialState = {
    carouselselected: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "setCarouselselected":
            return {
                ...state,
                carouselselected: action.payload
            };
        default:
            return state;
    }
};
