import React, { useContext, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IoMdNotifications } from 'react-icons/io'
import { FaMapMarkerAlt, FaPowerOff } from 'react-icons/fa';
import { BsPersonCircle, BsFillBoxFill, BsFillCaretUpFill, BsHeartFill } from 'react-icons/bs'
import { CategoryComp2 } from '../common';
import { IoSearch } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { VscAccount } from "react-icons/vsc";
import SearchBar from './SearchBar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Modal } from 'react-bootstrap';
import { ModalAddressList } from '../common';
import { FiShoppingCart } from "react-icons/fi";
import { GoPerson } from "react-icons/go";
import logo from '../../images/bg_image.webp';
import { ContactHeader } from '../common';
import { useSelector, useDispatch } from 'react-redux';
import { useCookie } from '../../hooks';
// import SearchForm from '../common/SearchForm';
// import Searchdrop from '../common/Searchdrop';

const Header1 = React.memo(() => {
  const { deleteCookie } = useCookie()
  const [selectedOption, setSelectedOption] = useState(null);
  const langdata = useSelector(state => state.masterdata.langdata);
  const slectedkeys = useSelector(state => state.masterdata.slectedkeys);
  const searchbarstatus = useSelector(state => state.masterdata.searchbarstatus);
  const keysdata = langdata && langdata[0]
    ? Object.keys(langdata[0])
    : [];
  const dispatch = useDispatch()
  const cartDetail = useSelector(state => state.cartdetail.cartDetail);
  const authStatus = useSelector(state => state.masterdata.authStatus);
  const addressList = useSelector(state => state.addressdata.addressList);
  const openaddressModal = useSelector(state => state.addressdata.openaddressModal);
  const userData = useSelector(state => state.masterdata.userData);
  const [addDetail, setAddDetail] = useState([])
  // const searchbaricon=()=>{
  //   dispatch({
  //     type: 'SetSearchbarstatus',
  //     payload: !searchbarstatus

  //   });
  // }
  useEffect(() => {
    if (cartDetail.result.filter(ele => ele.cart_status == 1).length < 1) {
      dispatch({
        type: "setcartpagerediredct",
        payload: false
      })
    }
  })
  const clicked = (data) => {
    // alert(data)
    dispatch({
      type: 'setslectedkeys',
      payload: data

    });
    setSelectedOption(data);
  }
  useEffect(() => {
    if (addressList != undefined) {
      const add = addressList.filter(ele => ele.default == 1);
      setAddDetail(add);
    }
  }, [addressList])

  const handleClose = () => {
    dispatch({ type: 'setopenaddressModal', payload: !openaddressModal })
  }

  return (


    <div className="header " >

      <div className='header-item-left'>
        <div className="logo" >
          <Link to="/">
            {
              <img referrerPolicy="no-referrer" src={localStorage.getItem('company_logo') != null && localStorage.getItem('company_logo')}
                onError={e => {
                  e.currentTarget.src = logo
                }}
              />
            }
          </Link>
        </div>

        <div onClick={() => dispatch({ type: 'setopenaddressModal', payload: true })} className="text-decoration-none">

        </div>
      </div>
      <div >
        <CategoryComp2 />
      </div>
      <div>
        {
          keysdata.length > 0 &&


          <NavDropdown title={`  ${selectedOption || 'ENGLISH'}`}>
            {
              keysdata.slice(1, keysdata.length).map(ele => (
                <NavDropdown.Item key={ele} onClick={() => clicked(ele)}>
                  {ele}
                </NavDropdown.Item>
              ))

            }

          </NavDropdown>
        }
      </div>


      <div className='header-item-right'>
        <Link to="/SearchComp" className='text-decoration-none'>
          <div className='hedar-search-icons-maindiv'><CiSearch className='iconsheader' /></div>
        </Link>
        <div className='ms-4 my_Account_header' style={{ display: "flex" }}>
          <GoPerson size="40" className="me-2 icon_color" />
          <div>
            <div className='dropdown-up-title'>
              {slectedkeys != '' && (langdata.length > 0) ? (
                langdata
                  .filter(comp => comp.KEYS === "header_navlink_hello")
                  .map(translatedData => (

                    <span key={translatedData.id}>
                      {translatedData[slectedkeys]}
                    </span>

                  ))
              ) : "Hello"

              }, {authStatus ? userData.name : slectedkeys != '' && (langdata.length > 0) ? (
                langdata
                  .filter(comp => comp.KEYS === "navlink_ sign in")
                  .map(translatedData => (

                    <span key={translatedData.id}>
                      {translatedData[slectedkeys]}
                    </span>

                  ))
              ) : "sign in"

              }</div>
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={slectedkeys != '' && (langdata.length > 0) ? (
                langdata
                  .filter(comp => comp.KEYS === "navlink_Accountandlist")
                  .map(translatedData => (

                    <span key={translatedData.id}>
                      {translatedData[slectedkeys]}
                    </span>

                  ))
              ) : "Account & Lists"

              }
            >
              {authStatus ?
                <>
                  <NavDropdown.Item >
                    <NavLink to={"/my-account"} className="text-decoration-none">

                      <div className='Profile-Dropdown'>
                        <div className='Profile-Dropdown-icon primaryColorcolor'> <BsPersonCircle /> </div>
                        <div className='Profile-Dropdown-name'>My Account</div>
                      </div>
                    </NavLink>

                  </NavDropdown.Item>

                  <NavDropdown.Item >
                    <NavLink to={"/my-orders"} className="text-decoration-none">
                      <div className='Profile-Dropdown'>
                        <div className='Profile-Dropdown-icon primaryColorcolor'>  <BsFillBoxFill /></div>
                        <div className='Profile-Dropdown-name'>Orders</div>
                      </div>
                    </NavLink>
                  </NavDropdown.Item>

                  <NavDropdown.Item >
                    <NavLink to={"/wishlist"} className="text-decoration-none">
                      <div className='Profile-Dropdown'>
                        <div className='Profile-Dropdown-icon primaryColorcolor'>    <BsHeartFill /></div>
                        <div className='Profile-Dropdown-name'>Wishlist</div>
                      </div>
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item >
                    <div className='Profile-Dropdown'>
                      <div className='Profile-Dropdown-icon primaryColorcolor'>  <IoMdNotifications /> </div>
                      <div className='Profile-Dropdown-name'>Notifications</div>
                    </div>

                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={deleteCookie}>
                    <div className='Profile-Dropdown'>
                      <div className='Profile-Dropdown-icon primaryColorcolor'>  <FaPowerOff /> </div>
                      <div className='Profile-Dropdown-name'>Sign Out</div>
                    </div>

                  </NavDropdown.Item>
                  <div className='Profile-Dropdown-icon-arrow primaryColorcolor'><BsFillCaretUpFill /></div>
                </>
                :

                <NavDropdown.Item >
                  <NavLink to={"/login"} className="text-decoration-none">
                    <div className='Profile-Dropdown'>
                      <div className='Profile-Dropdown-icon primaryColorcolor'>  <FaPowerOff /></div>
                      <div className='Profile-Dropdown-name'>Sign In</div>
                    </div>
                  </NavLink>

                </NavDropdown.Item>

              }

            </NavDropdown>
          </div>

        </div>






        <Link to="/cart" className='text-decoration-none'>
          <div className='cart'>
            <div>
              <FiShoppingCart
                className="cartIcon "
                size="22"
              />{" "}
              <span className="custom-badge primaryColor">{cartDetail.result.filter(ele => ele.cart_status == 1).length}</span>
            </div>
          </div>
        </Link>


      </div>

      <Modal
        show={openaddressModal}
        size="sm"
        className='my-custom-modal'
        centered
        onHide={() => handleClose()}
      >

        <Modal.Body>
          <ModalAddressList />
        </Modal.Body>
      </Modal>

    </div>

  );
});

export default Header1;