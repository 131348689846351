import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Row, Col } from 'react-bootstrap';

const AccountInfo = ({ type }) => {
    const profileData = useSelector(state => state.masterdata.profileData);
    const errors = useSelector(state => state.addressdata.errors);

    const dispatch = useDispatch()

    const [formValues, setFormValues] = useState(
        {
            name: "",
            mailid: "",
            phone: "",
        }
    )

    const handleChange = (event) => {
        event.persist();
        if (event.target.value == '') {
            dispatch({
                type: 'setErrors', payload: {
                    mailid: "Email id is required"
                }
            })
        } else {
            const { ['mailid']: tmp1, ...rest1 } = errors;
            dispatch({
                type: 'setErrors', payload: rest1
            })
        }
        setFormValues(values => ({ ...values, [event.target.name]: event.target.value }));
        dispatch({
            type: 'setcustomer_email', payload: event.target.value
        })
    };

    useEffect(() => {
        if (profileData.length > 0) {
            if (profileData[0].mailid == '' || profileData[0].mailid == null) {
                dispatch({
                    type: 'setErrors', payload: {
                        ...errors,
                        mailid: "Email id is required"
                    }
                })
            }
            setFormValues({ ...formValues, ...profileData[0] })
            dispatch({
                type: 'setcustomer_email', payload: profileData[0].mailid
            })
        }
    }, [profileData])

    return (
        <div className='p-4'>
            <Row>
                {/* <Col lg={6}>
                    <div className='field-holder' style={{
                        pointerEvents: 'none',
                        opacity: '1',
                    }}>
                        <input type={"text"} id={"name"} name={"name"} value={formValues.name} className={`custom-input custom-input-disabled `} />
                        <label htmlFor={"name"} className="custom-label">Name</label>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className='field-holder' style={{
                        pointerEvents: 'none',
                        opacity: '1',
                    }}>
                        <input type={"text"} id={"phone"} name={"phone"} value={formValues.phone} className={`custom-input custom-input-disabled `} />
                        <label htmlFor={"phone"} className="custom-label">Phone</label>
                    </div>
                </Col> */}
                <Col lg={6}>
                    <div className='field-holder'>
                        <input type={"text"} id={"mailid"} name={"mailid"} value={formValues.mailid} className={`custom-input ${errors.hasOwnProperty("mailid") ? 'is-error' : ''}`} required onChange={handleChange} />
                        <label htmlFor={"mailid"} className="custom-label primaryColorcolor">Email</label>
                    </div>
                    {errors.hasOwnProperty('mailid') && <span className='error-text'>{errors['mailid']}</span>}
                </Col>
            </Row>
        </div>

    );
};

export default AccountInfo;