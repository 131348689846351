import Apis from "../../services/Apis";
import { useSelector, useDispatch } from "react-redux";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";

const OrderAction = () => {
  const apidispatch = useDispatch()
  const { getPaymentMethodService, getCustomerOrder, postplaceorderService, updateCheckoutService, getOrderDetailByIdService, checkCustomerdata, conformpaymentservice } = Apis();
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();

  const selectedColor = useSelector(state => state.orderdetail.selectedColor);
  const userData = useSelector(state => state.masterdata.userData);
  const formValues = useSelector(state => state.addressdata.formValues);
  const coupanmsg = useSelector(state => state.cartdetail.coupanmsg);
  const billingformValues = useSelector(state => state.addressdata.billingformValues);
  const customer_email = useSelector(state => state.addressdata.customer_email);
  const razzer = useSelector(state => state.orderdetail.razzer);
  const getPaymentMethodlist = () => async dispatch => {

    const response = await getPaymentMethodService();
    dispatch({
      type: 'setRazzer',
      payload: response.data.data,

    });
  };
  const checkemail = (input_data) => async dispatch => {
    const response = await checkCustomerdata(input_data);
    if (response.data.status == 'error') {
      dispatch({
        type: 'setVerification_status',
        payload: response.data,

      });
    } else {
      dispatch({
        type: 'setVerification_status',
        payload: {},

      });
    }
    if (response.data.status == 'success') {
      dispatch({
        type: 'setemailotp',
        payload: response.data,

      });
    }
  };
  const getCustomerOrderdata = () => async dispatch => {

    const response = await getCustomerOrder();
    dispatch({
      type: 'setMyorder',
      payload: response.data.data,

    });
  };

  const postplaceorder = () => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    var input_data = {
      "payment_method_key": selectedColor.split("@")[1],
      "channel": "Website",
      "payment_method_id": selectedColor.split("@")[0],
      ...coupanmsg.hasOwnProperty("coupan_code") && { "coupan_code": coupanmsg.coupan_code },
      ...coupanmsg.hasOwnProperty("type") && { "type": coupanmsg.type },
      "customer_email": customer_email,
      "shipping_address": formValues,
      ...userData.hasOwnProperty("cust_type") && userData.cust_type == 2 && {
        "billing_address": {
          name: billingformValues.billingname,
          phone: billingformValues.billingphone,
          address: billingformValues.billingaddress,
          pincode: billingformValues.billingpincode,
          state: billingformValues.billingstate,
          addressId: "0",
          addresstype: "Billing"
        }
      },
    }
    const response = await postplaceorderService(input_data);
    if (response.data.status == "success") {
      if (response.data.payment_status == 'pending') {
        if (response.data.data.hasOwnProperty("redirect_url")) {
          window.location.href = response.data.data.redirect_url + `?callback=${window.location.origin + window.location.pathname}`;
        } else {
          const options = {
            key: response.data.data.razorpayKey, // Enter the Key ID generated from the Dashboard
            amount: response.data.data.total_amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: localStorage.getItem('agency_name') != null && localStorage.getItem('agency_name'),
            description: "Test Transaction",
            order_id: response.data.data.razorpayOrderId,
            image: localStorage.getItem('company_logo') != null && localStorage.getItem('company_logo'),

            // order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (responsech) {
              const checkout_data = {
                "razorpay_order_id": responsech.razorpay_order_id,
                "razorpay_payment_id": responsech.razorpay_payment_id,
                "razorpay_signature": responsech.razorpay_signature,
                "order_id": response.data.data.orderid,
                "payment_method_key": "Razorpay",
                "payment_method_id": "6",
              }
              apidispatch(updateCheckout(checkout_data))
            },

            "modal": {
              "ondismiss": function () {
                window.location.replace("/checkout");
              }
            },
            config: {
              display: {
                blocks: {
                  banks: {
                    name: 'All payment methods',
                    instruments: [
                      {
                        method: 'upi'
                      },
                      {
                        method: 'card'
                      },
                      {
                        method: 'netbanking'
                      }
                    ],
                  },
                },
                sequence: ['block.banks'],
                preferences: {
                  show_default_blocks: false,
                },
              },
            },

            theme: {
              // color: "#ef8121",
              // color: "#85481d",
              // color: "#B21217",
              color: "#da4644",
            },
          };
          const rzp1 = new Razorpay(options);
          rzp1.open();
        }
      }

      if (response.data.payment_status == 'success') {
        dispatch({
          type: 'setloader',
          payload: false
        });
        // navigate(`/order-success/${response.data.data.orderid}`)
      }

    }
  };

  const getOrderDetailById = (slug1, slug2) => async dispatch => {
    dispatch({
      type: 'setLoaderstatus',
      payload: true
    });
    const response = await getOrderDetailByIdService(slug1, slug2);
    dispatch({
      type: 'setLoaderstatus',
      payload: false
    });
    if (response.data.status == "success") {
      dispatch({
        type: 'setorderDetail',
        payload: response.data.data
      });

    }
  };
  const updateCheckout = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await updateCheckoutService(input_data);
    if (response.data.status == "success" || response.status == 200) {
      dispatch({
        type: 'setloader',
        payload: false
      });
      if (input_data.hasOwnProperty("subscription_id")) {
        // getCustomerSubscriptionData()
      }
      navigate(`/order-success/${response.data.data.orderid}`)
    }
  };
  const conformpaymentservicedata = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await conformpaymentservice(input_data);
    if (response.data.status == "success" || response.status == 200) {
      dispatch({
        type: 'setloader',
        payload: false
      });
      navigate(`/order-success/${response.data.data.orderid}`)
      // if (input_data.hasOwnProperty("subscription_id")) {
      //   // getCustomerSubscriptionData()
      // }
      // navigate(`/order-success/${response.data.data.orderid}`)
    }
  };
  return {
    getPaymentMethodlist: getPaymentMethodlist,
    postplaceorder: postplaceorder,
    getOrderDetailById: getOrderDetailById,
    updateCheckout: updateCheckout,
    getCustomerOrderdata: getCustomerOrderdata,
    checkemail: checkemail,
    conformpaymentservicedata: conformpaymentservicedata
  }
};

export default OrderAction;
