const initialState = {
  openModal:false,
  cartDetail: {
    result:[]
  },
  cartDetaildup:undefined,
  coupanmsg:[],
  error: null,
  bottomsheet:false,
  discount:0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'cart_list':
      return {
        ...state,
        cartDetail: action.payload
      };
    case 'setcartDetaildup':
      return {
        ...state,
        cartDetaildup: action.payload
      };
    case 'setCoupanmsg':
      return {
        ...state,
        coupanmsg: action.payload
      };
    case 'setbottomsheet':
      return {
        ...state,
        bottomsheet: action.payload
      };
    case 'setopenModal':
      return {
        ...state,
        openModal: action.payload
      };
    case 'setdiscount':
      return {
        ...state,
        discount: action.payload
      };
    default:
      return state;
  }
};
