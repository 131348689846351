import React,{useState,useContext} from 'react'
import { InputBox, OtpForm } from '../../components/common';
import validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation'
import MasterAction from '../../redux/actions/MasterAction';
import { useNavigate } from 'react-router-dom';
import {BsFillForwardFill,BsChevronDown,BsChevronUp} from 'react-icons/bs';
// import { StateContext } from '../components/context/ManageStates';
import { useOrders } from '../../hooks';
import { StateContext } from '../context/ManageStates';
import { useSelector,useDispatch } from 'react-redux';

const ForgotPassword3 = () => {
 const dispatch =useDispatch();
 const navigate=useNavigate()
    const [formValues, setFormValues] = useState(
        {
            change_old_password: "",
            change_new_password: "", 
        }
    )

    const {updatepwd,updateAuthPasswordservicedata} = MasterAction()

    // const { pwd_Email } = useContext(StateContext);
    const pwd_Email = useSelector(state => state.masterdata.pwd_Email);
    const forgotid = useSelector(state => state.masterdata.forgotid);
    const forgot_login_type = useSelector(state => state.masterdata.forgot_login_type);
    const forgotsuccess = useSelector(state => state.masterdata.forgotsuccess);
    const { values, errors, handleChange, handleSubmit,  } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );


 function handleNext() {
    if (localStorage.getItem("verification_method") == 1) {
        var input_data = {
            mobile: pwd_Email,
            password:values.change_old_password,
          
        }
        dispatch(updatepwd(input_data))
    } else {
        var input_data = {
            verify_id:forgotid,
            // email: pwd_Email,
            login_type:forgot_login_type,
            new_password:values.change_old_password
        }
        dispatch(updateAuthPasswordservicedata(input_data))
    }
}
if(forgotsuccess=="success"){
    navigate("/Login")
}
      
    return (
        <div className='ForgotPassword-mian-login'>
              <div className='ForgotPassword-main'>
            <div className='ForgotPassword-title'>Create new password</div>
            <div className='ForgotPassword-status'>We'll ask for this password whenever you sign in.</div>
            
          
            <InputBox label={"Old Password"} id={"change_old_password"} handleChange={handleChange} errors={errors} />
            <InputBox label={"New Password"} id={"change_new_password"} handleChange={handleChange} errors={errors} />
            <div className='product-detail-buttongroup login-register-btn'>
                        <div className={`product-detail-button primaryColor secondaryColorHover`} onClick={handleSubmit} >
                          Update Password
                        </div>
                    </div>
                    
        </div>
     
      
        </div>
      
    );
};

export default ForgotPassword3;
