import React, {useState} from 'react';
import { FaArrowRight } from "react-icons/fa";
import { InputBox } from '../common'
import ProductAction from '../../redux/actions/ProductAction'; // Assuming this is your action creator
import { useDispatch } from 'react-redux';

import validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import {Row,Col} from 'react-bootstrap'
function Newsletterform() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const { saveNewsLetterdatalist } = ProductAction(); // Assuming this is your action creator function
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const [formValues, setFormValues] = React.useState(
        {
          "mailid": "",
          "status":"1"
        }
      );
      const { values, errors, setErrors, handleChange, handleSubmit } = useFormvalidation(
        handleNext,
        validate,
        formValues
      );
     
    
      function handleNext() {
        dispatch(saveNewsLetterdatalist(values, errors,setErrors))
      }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setError(false);
    };

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     if (validateEmail(email)) {
    //         dispatch(saveNewsLetterdatalist({ email: email,status:1 })); // Dispatching the action with the email data
    //         setEmail('');
    //         setSubmitted(true);
    //         setError(false);
    //     } else {
    //         setError(true);
    //     }
    // };

    return (
        <div className="newsletter-form">
            <form onSubmit={handleSubmit}>
                <h5 className='footer-subscribe'>SUBSCRIBE</h5>
                {submitted && !error && <p className="success-message  footer-color-content">Thank you for subscribing!</p>}
                <Col lg={12} className='mt-4'>
              {/* <InputBox label={"Email"} id={"email"} handleChange={handleChange} errors={errors} /> */}
              <InputBox label={"Email"} id={"mailid"} classname="input-bg-black" subcribre="subscribe-email" handleChange={handleChange} errors={errors} handleSubmit={handleSubmit}  />
            </Col>
               
                {/* <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                    className={error ? "error" : ""}
                />
                <button type="submit" className='newsbutton footer-color-content'><FaArrowRight /></button> */}
                {error && <p className="error-message  footer-color-content">Please enter a valid email address.</p>}
            </form>
            <h6 className=' footer-color-content'>
                <p></p>Subscribe to our newsletter.
                <p>Be always in trend!</p>
            </h6>
        </div>
    );
}

export default Newsletterform;
