import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import { Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import HeadingTitle from "./HeadingTitle";

const Clientsdata = ({haedingdata,sliderdata}) => {
   
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const clientdata = [
        {
            "image": "https://htmldemo.net/makali/makali/img/testmonial/t1-user.webp",
            "name": "orando BLoom",
            "discription": "Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.Mirumest notare quam littera gothica, quam nunc putamus parum claram."
        },
        {
            "image": "https://htmldemo.net/makali/makali/img/testmonial/t1-user.webp",
            "name": "orando BLoomrrrrrrrrrrrr",
            "discription": "Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.Mirumest notare quam littera gothica, quam nunc putamus parum claram."
        },
        {
            "image": "https://htmldemo.net/makali/makali/img/testmonial/t1-user.webp",
            "name": "orando BLoomyyyyyyyyyyyyyyyy",
            "discription": "Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.Mirumest notare quam littera gothica, quam nunc putamus parum claram."
        }
    ]

    return (
        <div style={{
            backgroundImage: `url(${haedingdata.background_image})`,
            backgroundSize: 'cover',      // Ensures the background covers the whole container
        }}>
            <HeadingTitle className={"center"} className1={"Heading-maindiv"} HeadingTitle={haedingdata.section_title} content={haedingdata.section_description} titleclass={"headtitle-testmorial"} discriptionclass={"headdiscription-testmorial"}/>
            <div className="swiper-main">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                    spaceBetween={20}
                    loop={true}
                    pagination={{ clickable: true }}
                    // autoplay={{ delay: 5000 }}

                    breakpoints={{
                        200: {
                            slidesPerView: 1,
                        },
                        700: {
                            slidesPerView: 1,
                        },
                        1800: {
                            slidesPerView: 1,
                        },
                    }}
                >
                    {
                        sliderdata.map(ele=>{
                            return(
                                <SwiperSlide >
                                <div class="testmonial-active owl-carousel">
                                    <div class="single-testmonial text-center">
                                        <div class="testmonial-content">
                                            <h6 class="testmonial-discription">{ele.description}</h6>
                                            <img class="clientimage" src={ele.image}></img>
                                            <span class="t-author">{ele.title}</span>
                                        </div>
                                    </div>
        
                                </div>
                            </SwiperSlide>
                            )
                        })
                    }
                   

                </Swiper>
                {/* <div>
                <div className={`previousbtn previousbtn${position}`}><BiSolidChevronLeft /></div>
                <div className={`nextbtn nextbtn${position}`}><BiSolidChevronRight /></div>
            </div> */}
            </div>
        </div>
    );
}


export default Clientsdata;