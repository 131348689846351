import React,{useContext,useState} from 'react';
import { MdOutlineClose } from 'react-icons/md'
import { ProfileForm, InputBox } from '../common';
import { StateContext } from '../context/ManageStates';
import validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation'
// import { useAccount } from '../../hooks';
import MyAccountAction from "../../redux/actions/MyAccountAction"
import { useSelector,useDispatch } from 'react-redux';
const OtpForm = () => {
    // const { setopenModal,otpformType, openModal, otpform } = useContext(StateContext)
    const dispatch = useDispatch()
    const otpformType = useSelector(state => state.masterdata.otpformType);
    const openModal = useSelector(state => state.masterdata.openModal);
    const otpform = useSelector(state => state.masterdata.otpform);
    const { sendotp } = MyAccountAction();

    const [formValues, setFormValues] = useState(
        otpformType == 'phone' ?
        {
            otp: "",
            mobile:""
        }
        :
        {
            email: "",
            otp: "",
        }
    )

    const { values, errors, handleChange, handleSubmit, handlePictureChange } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );


    function handleNext() {
        sendotp(values, errors)
        // Login(values, errors)
    }


    return (
        <div className='custom-modal-component'>
            <div className='custom-modal-header'>
                <div className='modal-header-title'>
                    {
                        otpform == 0 ?
                            `${otpformType == 'phone' ? "Enter New Mobile Number" : "Enter New Email Address"}`
                            :
                            "Enter OTP To Change Email Address"
                    }
                </div>
                <div className='custom-modal-closeicon'
                    onClick={() => 
                        dispatch({
                            type: 'setopenModal', payload: !openModal
                        })
                    }
                >
                    <MdOutlineClose size={"20"} />
                </div>
            </div>
            {
                otpform == 0
                    ?

                        otpformType == 'phone' ? 
                        <InputBox label={"Phone"} id={"mobile"} key={otpform} handleChange={handleChange} errors={errors} />
                        :
                        <InputBox label={"Email"} id={"email"} key={otpform} handleChange={handleChange} errors={errors} />

                    :
                    <InputBox label={"Enter Otp"} id={"otp"} key={otpform} handleChange={handleChange} errors={errors} value={values.otp} />
            }
            <div className='product-detail-buttongroup send_otp_btn' onClick={handleSubmit}>
                <div className={`product-detail-button primaryColor `}>

                    {
                        otpform == 0 ?
                            " Send OTP for verification"
                            :
                            "Confirm Otp"
                    }
                </div>
            </div>
        </div>
    );
};

export default OtpForm;