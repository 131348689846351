import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
const Homethreebanner = ({data}) => {
    const brands = [
        {
            image: "https://htmldemo.net/makali/makali/img/banner/cosmetic/1-1.webp",
            title: "Autumn",
            url: "autumn-1014101"
        },
        {
            image: "https://htmldemo.net/makali/makali/img/banner/cosmetic/1-2.webp",
            title: "Pink pastel Package",
            url: "pink-pastel-package-1015101"
        },
        {
            image: "https://htmldemo.net/makali/makali/img/banner/cosmetic/1-3.webp",
            title: "Pink pastel Package",
            url: "pink-pastel-package-1015101"
        }
    ]
    // const [our1, our2, our3, our4] = brands;
    return (
        <div className='top-space'>
            <Container fluid>

                <Row>
                    {
                        data && data.length > 0 &&                        
                        data.map(ele => {
                            return (
                                <Col lg={4} md={4} sm={4} xs={12} className='brands-col-maindivs'>
                                    <div class="single-categorie">
                                        <div class="cat-img">
                                            <NavLink to={ele.url} className="text-decoration-none">
                                            <img src={ele.card_image}></img>
                                            </NavLink>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    );
};
export default Homethreebanner;